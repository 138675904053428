const round = (n, p) => {
  const val = p !== undefined ? Math.pow(10, p) : 1000;
  return Math.round(n * val) / val;
};

const isFirefox = () => navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

export default class Scroll {
  constructor(config = {}) {
    this.container = document.body && config.container;

    this.tick = false;
    this.smooth = true;

    this.inertiaCoef = 0.05;
    this.spaceGapCoef = 2;
    this.maxFrom = 1;

    this.directions = {
      forward: 'forward',
      reverse: 'reverse'
    };

    this.coef = ('ontouchstart' in window) ? 4 : 2;
    this.wheelEvent = 'onwheel' in document ? 'wheel' : document.onmousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll';

    this.raf = this.raf.bind(this);
    this.run = this.run.bind(this);
    this.gRaf = this.gRaf.bind(this);

    this.touchStart = this.touchStart.bind(this);
    this.touchMove = this.touchMove.bind(this);

    // this.mouseDown = this.mouseDown.bind(this);
    // this.mouseUp = this.mouseUp.bind(this);
    // this.mouseMove = this.mouseMove.bind(this);
  }

  setScrollMax(x = this.scroll.max.x, y = this.scroll.max.y) {
    this.scroll.max = Object.assign(this.scroll.max, { x, y });
  }

  onUpdate() {
    // DO NOT REMOVE! IS NOT EMPTY METHOD
  }

  pause() {
    this.isScrollListened = false;
  }

  start() {
    this.isScrollListened = true;
  }

  on() {
    this.tick = false;
    this.isScrollListened = true;

    this.resetValues();
    this.setValues();
    this.addEvents();
    this.raf();
  }

  off() {
    this.tick = true;
    this.isScrollListened = false;
    
    this.removeEvents();
    this.resetValues();
  }

  onProgress(callback = () => {}) {
    callback(this.current);
  }

  resetValues() {
    this.target = {
      x: 0,
      y: 0
    };

    this.current = {
      x: 0,
      y: 0
    };

    this.tick = false;
  }

  setValues() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;

    const { scrollHeight, scrollWidth } = this.container;

    this.scroll = {
      max: {
        x: scrollWidth - this.width,
        y: scrollHeight - this.height
      },
      gap: {
        x: this.width / this.spaceGapCoef,
        y: this.height / this.spaceGapCoef
      }
    };

    this.drag = {
      pos: {
        x: 0
      },
      start: {
        x: 0
      }
    };
  }

  gRaf(e) {
    this.e = e;

    if(e.type !== 'keydown') { e.preventDefault(); }
    if (this.tick) return;
    this.tick = true;

    // window.requestAnimationFrame(this.run);
    this.run();
  }

  // mouseUp() {
  //   this.isDragging = false;
  // }

  // mouseMove({ clientX, targetTouches }) {
  //   if (!this.isDragging) return;

  //   const posX = targetTouches ? targetTouches[0].pageX : clientX;
  //   const offsetX = posX - this.drag.start.x;
  //   this.target.x = this.drag.pos.x + offsetX;

  //   this.getCb();

  //   this.onUpdate(this.current, this.target);
  // }

  // mouseDown({ clientX, targetTouches }) {
  //   if (!this.isScrollListened) return;
  //   this.isDragging = true;

  //   this.drag.start.x = targetTouches ? targetTouches[0].pageX : clientX;
  //   this.drag.pos.x = this.target.x;
  // }

  addEvents(){
    this.container.addEventListener(this.wheelEvent, this.gRaf, { passive: false });

    // this.container.addEventListener('touchstart', this.touchStart, { passive: false });
    // this.container.addEventListener('touchmove', this.gRaf, { passive: false });
    // this.container.removeEventListener('touchend', this.mouseUp);

    // this.container.addEventListener('mousedown', this.mouseDown);
    // this.container.addEventListener('mouseup', this.mouseUp);
    // this.container.addEventListener('mousemove', this.mouseMove);

    // document.addEventListener('keydown', this.mygraf);

    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
  }

  removeEvents(){
    window.cancelAnimationFrame(this.raf);

    this.container.removeEventListener(this.wheelEvent, this.gRaf);

    // this.container.removeEventListener('touchstart', this.touchStart);
    // this.container.removeEventListener('touchmove',this.gRaf);

    // this.container.removeEventListener('mousedown', this.mouseDown);
    // this.container.removeEventListener('mouseup', this.mouseUp);
    // this.container.removeEventListener('mousemove', this.mouseMove);

    // document.removeEventListener('keydown',this.mygraf);
  }

  run() {
    const { type: t } = this.e;

    if (!this.isScrollListened) return;
    'wheel' === t ? this.onWheel() : 'mousewheel' === t ? this.onMouseWheel() : 'touchmove' === t ? this.touchMove() : 'keydown' === t && this.keyDown();
  }

  onMouseWheel() {
    const ty = this.e.wheelDeltaY ? this.e.wheelDeltaY : this.e.wheelDelta;
    const tx = this.e.wheelDeltaX ? this.e.wheelDeltaX : this.e.wheelDelta;

    this.target.y += ty;
    this.target.x += tx;

    this.getCb();
  }

  onWheel() {
    let ty = this.e.wheelDelta || this.e.wheelDeltaY || -1 * this.e.deltaY;
    let tx = this.e.wheelDelta || this.e.wheelDeltaX || -1 * this.e.deltaX;

    isFirefox() && 1 === this.e.deltaMode && (tx *= 60) && (ty *= 60),
    tx *= 0.556, ty *= 0.556;

    this.target.y += ty;
    this.target.x += tx;

    this.getCb();
  }

  touchStart(e) {
    this.prevMoveY = this.target.y;
    this.prevMoveX = this.target.x;

    this.startY = e.targetTouches[0].pageY;
    this.startX = e.targetTouches[0].pageX;
  }

  touchMove() {
    this.target.y = this.e.targetTouches.length ? this.coef * (this.e.targetTouches[0].pageY - this.startY) + this.prevMoveY : this.target.y;
    this.target.x = this.e.targetTouches.length ? this.coef * (this.e.targetTouches[0].pageX - this.startX) + this.prevMoveX : this.target.x;

    this.getCb();
  }

  keyDown() {
    const t = this.e.keyCode;
    let i = void 0;

    const { y: spaceGapY } = this.scroll.gap;

    // @TODO add keys for x
    i = 38 === t ? 100 : 40 === t ? -100 : 32 === t && this.e.shiftKey ? spaceGapY : 32 === t ? -spaceGapY : 0, this.target.y += i;

    this.getCb();
  }

  getCb() {
    const { y: scrollYMax, x: scrollXMax } = this.scroll.max;

    this.target.y = round(Math.max(Math.min(this.target.y, 0), -scrollYMax - this.maxFrom), 3);
    this.target.x = round(Math.max(Math.min(this.target.x, 0), -scrollXMax - this.maxFrom), 3);

    this.tick = false;
  }

  setPos(obj, { x, y }) {
    return Object.assign(obj, { x, y });
  }

  setScrollPos(x = 0, y = 0) {
    this.target = this.setPos(this.target, { x, y });
    this.current = this.setPos(this.current, { x, y });
  }

  scrollTo(x = 0, y = 0) {
    this.target = this.setPos(this.target, { x, y });
  }

  getDirection({x, y}, {x: x1, y: y1}) {
    const { forward, reverse } = this.directions;

    return {
      x: x > x1 ? forward : reverse,
      y: y > y1 ? forward : reverse
    };
  }

  calcScrollPos() {
    if (this.smooth) {
      const { x: scrollXMax, y: scrollYMax } = this.scroll.max;
      const { x: x1, y: y1 } = this.target;
      const { x, y } = this.current;

      if (y <= -scrollYMax) { this.target.y -= .2 * (y1 + scrollYMax); }
      this.current.y += this.inertiaCoef * (y1 - y);

      if (x <= -scrollXMax) { this.target.x -= .2 * (x1 + scrollXMax); }
      this.current.x += this.inertiaCoef * (x1 - x);
    }

    this.onUpdate(this.current);
  }

  raf() {
    if (this.isScrollListened) this.calcScrollPos();
    window.requestAnimationFrame(this.raf);
  }
}

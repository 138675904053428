export default {
  background: {
    intro: [
      {
        name: "intro",
        src: "./audio/background/1_intro.mp3",
      },
    ],
    first: [
      {
        name: "main",
        src: "./audio/background/1/2_main.mp3",
      },
      {
        name: "breakpoint",
        src: "./audio/background/1/3_pad.mp3",
      },
      {
        name: "hysterics",
        src: "./audio/background/1/4_gay.mp3",
      },
      {
        name: "rejection",
        src: "./audio/background/1/5_uncertainty.mp3",
      },
      {
        name: "text",
        src: "./audio/background/1/6_text.mp3",
      },
      {
        name: "final",
        src: "./audio/background/1/7_final.mp3",
      },
    ],
    second: [
      {
        name: "main",
        src: "./audio/background/2/2_main.mp3",
      },
      {
        name: "breakpoint",
        src: "./audio/background/2/3_breakpoint.mp3",
      },
      {
        name: "hysterics",
        src: "./audio/background/1/3_pad.mp3",
      },
      {
        name: "trying",
        src: "./audio/background/2/4_trying.mp3",
      },
      {
        name: "rejection",
        src: "./audio/background/2/5_uncertainty.mp3",
      },
      {
        name: "text",
        src: "./audio/background/2/6_text.mp3",
      },
      {
        name: "final",
        src: "./audio/background/2/7_final.mp3",
      },
    ],
  },
  sections: {
    intro: [
      {
        name: "logo",
        src: "./audio/sounds/intro/1_main_logo.mp3",
      },
    ],
    first: [
      {
        name: "isolation",
        src: "./audio/" + LANG + "/NDI_Family_1_(Gay).mp3",
      },
      {
        name: "breaking",
        src: "./audio/sounds/6_breaking_photo_line_upd.mp3",
      },
      {
        name: "hystericsLine",
        src: "./audio/sounds/7_breaking_line.mp3",
      },
      {
        name: "hystericsEndLine",
        src: "./audio/sounds/8_line_1.mp3",
      },
      {
        name: "text",
        src: "./audio/sounds/13_line_sin.mp3",
      },
      {
        name: "sinToLine",
        src: "./audio/sounds/14_sin_to_line.mp3",
      },
      {
        name: "final",
        src: "./audio/sounds/15_recovering_photo_upd.mp3",
      },
      {
        name: "son-0",
        src: "./audio/sounds/son-0.mp3",
      },
      {
        name: "son-1",
        src: "./audio/sounds/son-1.mp3",
      },
      {
        name: "son-2",
        src: "./audio/sounds/son-2.mp3",
      },
      {
        name: "son-3",
        src: "./audio/sounds/son-3.mp3",
      },
      {
        name: "son-4",
        src: "./audio/sounds/son-4.mp3",
      },
      {
        name: "trying-0",
        src: "./audio/sounds/crossout_1-0.mp3",
      },
      {
        name: "trying-1",
        src: "./audio/sounds/crossout_1-1.mp3",
      },
      {
        name: "trying-2",
        src: "./audio/sounds/crossout_1-2.mp3",
      },
      {
        name: "trying-3",
        src: "./audio/sounds/crossout_1-3.mp3",
      },
    ],
    second: [
      {
        name: "isolation",
        src: "./audio/" + LANG + "/NDI_Family_2_Узнала_что_дочь_мужчина_.mp3",
      },
      {
        name: "hysterics",
        src: "./audio/" + LANG + "/NDI_Family_2_Узнала_что_дочь_лесбиянка_.mp3",
      },
      {
        name: "breaking",
        src: "./audio/sounds/6_breaking_photo_line_upd.mp3",
      },
      {
        name: "breakingLine",
        src: "./audio/sounds/f2_21_breaking_line.mp3",
      },
      {
        name: "hystericsLine",
        src: "./audio/sounds/7_breaking_line.mp3",
      },
      {
        name: "hystericsEndLine",
        src: "./audio/sounds/8_line_1.mp3",
      },
      {
        name: "text",
        src: "./audio/sounds/13_line_sin.mp3",
      },
      {
        name: "sinToLine",
        src: "./audio/sounds/14_sin_to_line.mp3",
      },
      {
        name: "final",
        src: "./audio/sounds/15_recovering_photo_upd.mp3",
      },
      {
        name: "son-0",
        src: "./audio/sounds/son-0.mp3",
      },
      {
        name: "son-1",
        src: "./audio/sounds/son-1.mp3",
      },
      {
        name: "son-2",
        src: "./audio/sounds/son-2.mp3",
      },
      {
        name: "son-3",
        src: "./audio/sounds/son-3.mp3",
      },
      {
        name: "son-4",
        src: "./audio/sounds/son-4.mp3",
      },
      {
        name: "trying-0",
        src: "./audio/sounds/crossout_2-0.mp3",
      },
      {
        name: "trying-1",
        src: "./audio/sounds/crossout_2-1.mp3",
      },
      {
        name: "trying-2",
        src: "./audio/sounds/crossout_2-2.mp3",
      },
      {
        name: "trying-3",
        src: "./audio/sounds/crossout_1-3.mp3",
      },
    ],
  },
};

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["handleVideoFinish"] }] */

import videojs from 'video.js';
import '../lib/videojs-panorama.v5';

import gsap from 'gsap';

import CONSTS from '../consts';

const { tags } = CONSTS;

export default class Video360 {
  constructor({ id }) {
    this.classes = {
      container: '.js-video-360',
      map: '.js-video-map svg:nth-child(2)',
      progress: '.js-video-360-progress',
      arrow: {
        left: '.js-video360-left',
        right: '.js-video360-right'
      }
    };

    this.options = {
      plugins: {
        panorama: {
          autoMobileOrientation: true,
          backToHorizonCenter: false,
          clickAndDrag: true,
          clickToToggle: true,
          maxLat: 0,
          minLat: 0,
          scrollable: false,
          showNotice: false
        }
      },
      height: '100%',
      width: '100%',
    };

    this.percentage = {
      1: true,
      50: true,
      100: true
    };

    this.id = id;

    this.handleInitVideo = this.handleInitVideo.bind(this);
    this.handleVideoFinish = this.handleVideoFinish.bind(this);
    this.handleVideoUpdate = this.handleVideoUpdate.bind(this);

    this.moveLeft = this.moveLeft.bind(this);
    this.moveRight = this.moveRight.bind(this);

    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  getStoryValue() {
    return this.id === 'first' ? 1 : 2;
  }

  handleVideoFinish() { /* DO TOT REMOVE */ }

  resize() {
    this.canvas.handleResize();
  }

  handleVideoUpdate() {
    const {
      currentTime,
      duration
    } = this.video;

    const progress = currentTime / duration;

    const value = (currentTime / (duration / 100)).toFixed();

    if (this.percentage[value]) {
      this.percentage[value] = false;

      window.trackAction(`${tags.actions.video}_${value}`, {
        event_category: `history-${this.getStoryValue()}`
      });
    }

    this.progress.style.transform = `scaleX(${progress})`;
  }

  isPlaying() {
    return !this.player.paused();
  }

  changeVolume(volume) {
    this.player.volume(volume);
  }

  unmute() {
    this.player.muted(false);
  }

  mute() {
    this.player.muted(true);
  }

  pause() {
    this.player.pause();

    this.player.on('timeupdate', this.handleVideoUpdate);
    this.player.off('ended', this.handleVideoFinish);
  }

  play() {
    this.player.play();

    this.player.on('timeupdate', this.handleVideoUpdate);
    this.player.on('ended', this.handleVideoFinish);
  }

  rotateTo(angle) {
    if (this.mainRotationTl && this.mainRotationTl.isActive()) return;
    if (this.rotationTl && this.rotationTl.isActive()) this.rotationTl.pause().progress(1);

    const obj = {
      x: this.canvas.lon
    };

    this.rotationTl = gsap.to(
      obj,
      2,
      {
        x: this.canvas.lon + angle,
        ease: 'power2.linear',
        onUpdate: () => {
          this.canvas.lon = obj.x;
          this.setMapPosition(obj.x);
        }
      }
    );
  }

  rotate360() {
    if (!this.canvas) {
      this.rotate360();
      return;
    }

    const obj = {
      x: this.canvas.lon
    };

    this.mainRotationTl = gsap.to(
      obj,
      7.5,
      {
        x: obj.x * (-1),
        ease: 'power2.linear',
        onUpdate: () => {
          this.canvas.lon = obj.x;
          this.setMapPosition(obj.x);
        },
        onComplete: () => {
          gsap
            .timeline()
            .add(
              gsap
                .timeline({
                  repeat: 1
                })
                .fromTo(this.arrowRight, 0.8, { xPercent: 0 }, { xPercent: 25, ease: 'power2.easeOut'})
                .to(this.arrowRight, 0.8, { xPercent: 0, ease: 'power2.easeOut' })
            )
            .add(
              gsap
                .timeline({
                  repeat: 1
                })
                .fromTo(this.arrowLeft, 0.8, { xPercent: 0 }, { xPercent: -25, ease: 'power2.easeOut'})
                .to(this.arrowLeft, 0.8, { xPercent: 0, ease: 'power2.easeOut' })
            )
          ;
        }
      }
    );
  }

  setMapPosition(angle) {
    this.map.style.transform = `rotate(${angle}deg)`;
  }

  moveRight() {
    this.rotateTo(180);
  }

  moveLeft() {
    this.rotateTo(-180);
  }

  handleMouseMove() {
    if (!this.canvas) {
      this.setValues();
      return;
    };

    const { mouseDown } = this.canvas;
    if (!mouseDown) return;

    this.setMapPosition(this.canvas.lon);
  }

  initHandlers() {
    this.player.on('mousemove', this.handleMouseMove);

    this.arrowLeft.addEventListener('click', this.moveLeft);
    this.arrowRight.addEventListener('click', this.moveRight);
  }

  setValues() {
    this.canvas = this.player.getChild('Canvas');
  }

  handleInitVideo() {
    this.isLoaded = true;

    this.setValues();
    this.initHandlers();

    const interval = window.setInterval(() => {
      this.setValues();
      if (this.canvas) window.clearInterval(interval);
    }, 100);
  }

  initPlayer() {
    this.player = videojs('#js-video-360', this.options, this.handleInitVideo);
  }

  initValues() {
    this.map = this.container.querySelector(this.classes.map);
    this.progress = this.container.querySelector(this.classes.progress);
    this.video = this.container.querySelector('video');

    this.arrowRight = this.container.querySelector(this.classes.arrow.right);
    this.arrowLeft = this.container.querySelector(this.classes.arrow.left);

    this.translationTrack = document.getElementById('video-translation');
  }

  destroy() {
    this.player.dispose();
  }

  init() {
    this.container = document.querySelector(this.classes.container);
    if (!this.container) return;

    this.initValues();
    this.initPlayer();
  }
};

import { isEn } from "../helpers";

export default [
  // loader
  "./img/loader-bg.jpg",
  "./img/noise.png",
  "./img/back.jpg",
  // intro
  `./img/${LANG}/h-mom-1.png`,
  `./img/${LANG}/h-mom-2.png`,
  "./img/mom-1.png",
  "./img/mom-2.png",
  // story 1
  "./img/timeline/1/dima-small-1.jpg",
  "./img/timeline/1/dima-small-2.jpg",
  "./img/timeline/1/dima-small-3.jpg",
  "./img/timeline/1/dima-small-4.jpg",
  "./img/timeline/1/plane.png",
  // story 2
  "/img/timeline/2/sebastian-small-1.jpg",
  "/img/timeline/2/sebastian-small-2.jpg",
  "/img/timeline/2/sebastian-small-3.jpg",
  "/img/timeline/2/sebastian-small-4.jpg",
  "/img/timeline/2/tiger.png",
  // videos
  "./video/hysteric-1.mp4",
  "./video/hysteric_2-1.mp4",
  "./video/hysteric_2-2.mp4",
  // music
  "./audio/background/1_intro.mp3",
  "./audio/background/1/2_main.mp3",
  "./audio/background/2/2_main.mp3",
];

import gsap from 'gsap';

import CONSTS from '../../consts';

const { tags } = CONSTS;

const CLASSES = {
  container: '.js-son',
  text: '.js-son-text',
  item: '.js-son-item',
  hovered: '.js-son-hovered-item',
  title: '.js-son-title',
  progress: '.js-progress',
  arrows: '.js-timeline-arrows'
};

export default {
  name: 'son',
  reverse: {
    forwards: ({ canvas, music }) => gsap
      .timeline({
        paused: true
      })
      .to(
        CLASSES.progress,
        1,
        {
          autoAlpha: 1,
          onStart: () => {
            canvas.setBrokenImageOpacity(0);
            music.setState(false);
            music.resetCurrent();
            music.pauseAll();
            music.play('final');
          }
        },
        '-=1'
      ),
    reverse: () => gsap
      .timeline({
        paused: true
      })
      .to([CLASSES.arrows, CLASSES.progress], 1, { autoAlpha: 1 })
  },
  timeline: ({ slide, music, canvas, id }) => {
    const container = document.querySelector(CLASSES.container);

    const texts = [...container.querySelectorAll(CLASSES.text)];
    const items = [...container.querySelectorAll(CLASSES.item)];
    const titles = [...container.querySelectorAll(CLASSES.title)];

    const { length } = texts;
    const coef = 1 / length;

    const progress = {
      x: 0
    };

    const animations = texts.map((item, index) => {
      const tl = gsap
        .timeline({
          paused: true
        })
      ;

      const isLastItem = index === (length - 1);

      if (index === 1) {
        tl
          .to([item, items[index]], 1, { autoAlpha: 1 })
          .to(titles[0], 1, { autoAlpha: 1 }, 0)
        ;
      } else if (index) {
        tl.fromTo([item, items[index]], 1, { x: 100 }, { autoAlpha: 1, x: 0 });

        if (isLastItem) tl.to(titles[index - 1], 1, { autoAlpha: 1 }, 0);
      } else {
        tl.to(
          [CLASSES.progress],
          1,
          {
            autoAlpha: 1,
            onStart: () => {
              canvas.setBrokenImageOpacity(0);
              music.setState(false);
              music.resetCurrent();
              music.pauseAll();
              music.play('final');
            }
          }
        );
      }

      const speed = isLastItem ? 1 : 0.4;

      tl
        .to(
          progress,
          speed,
          {
            x: () => {
              if (index === texts.length - 1) return 1;
              return index ? progress.x + coef * 0.5 : coef;
            },
            ease: 'power4.out',
            onStart: () => {
              music.playTrack(`son-${index}`);
              window.analytics.trackPage('childtext', `${tags.pages.story[id].subpage}${tags.pages.childtext}${index + 1}`);
            },
            onUpdate: () => {
              const line = slide.getLines()[0];
              line.geometry.maxInstancedCount = progress.x * 100;
            },
            onComplete: () => {
              if (index === 1) container.classList.add('is-first-section-played');
            }
          },
          `+=${index ? -1 : 0.5}`
        )
      ;

      const prevItem = items[index - 1];
      if (prevItem) {
        if (index === 1) {
          tl.to([items[index - 1], texts[index - 1], CLASSES.hovered], 1, { autoAlpha: 0 }, 0);
        } else {
          tl
            .to([items[index - 1], texts[index - 1]], 1, { autoAlpha: 0, x: -100 }, 0)
          ;
        }
        if (isLastItem) tl.to(titles[0], 1, { autoAlpha: 0 }, 0);
      }

      tl.to(CLASSES.container, 1, { autoAlpha: 1 });

      return tl;
    });

    return animations;
  }
};

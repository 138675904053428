import gsap from 'gsap';

import CONSTS from '../../consts';

const { tags } = CONSTS;

const CLASSES = {
  container: '.js-final',
  line: '.js-final-line',
  text: '.js-final-text',
  progress: '.js-progress',
  arrows: {
    right: '.js-timeline-arrow-right'
  }
};

export default {
  name: 'final',
  reverse: {
    reverse: ({ music }) => gsap
      .timeline({
        paused: true
      })
      .to(
        CLASSES.progress,
        1,
        {
          autoAlpha: 1,
          onStart: () =>  {
            music.pauseAll();
            music.play('text');
            music.changeVolume(1);
          }
        },
        '-=1'
      )
      .to(CLASSES.arrows.right, 1, { autoAlpha: 1 }, '-=1')
    ,
    forwards: ({ canvas, music }) => {
      const progress = {
        x: 1
      };

      return gsap
        .timeline({
          paused: true
        })
        .fromTo(
          progress,
          1.2,
          {
            x: 1
          },
          {
            x: 0,
            ease: 'power4.out',
            onStart: () => {
              music.playTrack('final');
            },
            onUpdate: () => {
              canvas.setBrokenImageProgress(progress.x);
            },
            onComplete: () => { progress.x = 1; }
          },
          '+=1.2'
        )
    }
  },
  timeline: ({ canvas, music, id }) => {
    const progress = {
      x: 1
    };

    return gsap
      .timeline({
        paused: true
      })
      .fromTo(CLASSES.text, 1, { opacity: 0, y: -20 }, { opacity: 1, y: 0, stagger: 1.4 }, '+=1.2')
      .fromTo(
        progress,
        1.2,
        {
          x: 1
        },
        {
          x: 0,
          ease: 'power4.out',
          onStart: () => {
            setTimeout(() => music.playTrack('final'), 80);
            window.analytics.trackPage('final', `${tags.pages.story[id].subpage}${tags.pages.final}`);
          },
          onUpdate: () => {
            canvas.setBrokenImageProgress(progress.x);
          },
          onComplete: () => { progress.x = 1; }
        },
        '-=1'
      )
  }
};

import PerfectScrollbar from 'perfect-scrollbar';

export default class Tabs {
  constructor(config) {
    this.container = config && config.container;

    this.classes = {
      tab: '.js-tabs-link',
      content: '.js-tabs-content',
      states: {
        active: 'is-active'
      },
      scrollbar: '.js-tabs-scrollbar'
    };

    this.handleClick = this.handleClick.bind(this);

    this.init();
  }

  setActiveItem(link, id) {
    const { active } = this.classes.states;

    link.classList.add(active);
    this.container.querySelector(`[data-content="${id}"]`).classList.add(active);
  }

  updateScrollbarsPosition() {
    this.scrollbars.forEach(item => item.update());
  }

  resetItems() {
    this.tabs.forEach(item => item.classList.remove(this.classes.states.active));
    this.contents.forEach((item) => {
      const scroll = item;

      scroll.classList.remove(this.classes.states.active);
      scroll.scrollTop = 0;

      scroll.removeAttribute('style');
    });
  }

  handleClick({ target }) {
    const link = target.closest(this.classes.tab);
    if (!link) return;

    const id = link.dataset && link.dataset.contentId;
    if (!id) return;

    this.resetItems();
    this.setActiveItem(link, id);
    this.updateScrollbarsPosition();
  }

  initScrollbars() {
    const containers = [...this.container.querySelectorAll(this.classes.scrollbar)];
    this.scrollbars = containers.map(item => new PerfectScrollbar(item, {
      wheelPropagation: true,
      useBothWheelAxes: true
    }));
  }

  initHandlers() {
    this.container.addEventListener('click', this.handleClick);
  }

  initValues() {
    this.tabs = [...this.container.querySelectorAll(this.classes.tab)];
    this.contents = [...this.container.querySelectorAll(this.classes.content)];
  }

  init() {
    if (!this.container) return;

    this.initValues();
    this.initHandlers();
    this.initScrollbars();
  }
};

import gsap from 'gsap';

import CONSTS from '../../consts';

const { tags } = CONSTS;

const CLASSES = {
  container: '.js-breakpoint',
  item: '.js-breakpoint-item'
};

let played = false;

const updateLineValue = (item, value, maxInstance = 110) => {
  if (!item) return;

  const line = item;
  line.geometry.maxInstancedCount = value * maxInstance;
};

const getProgressValue = (canvas, progress) => {
  const currentProgress = canvas.getBrokenImageProgress();
  return currentProgress > Number(progress.value) ? currentProgress : progress.value;
}

export default {
  name: 'breakpoint',
  reverse: {
    reverse: ({ music, id }) => ({
      play() {
        if (id === 'second') {
          if (music.name === 'isolation') {
            music.play('trying');
          } else {
            music.play('breakpoint');
          }
        }
      }
    }),
    forwards: ({ canvas, music }) => {
      const progress = {
        value: 0
      };

      return gsap
        .timeline({
          paused: true
        })
        .set(progress, { value: 0 })
        .to(
          progress,
          1.4,
          {
            value: 1,
            ease: 'power4.out',
            onStart: () => {
              if (music.name === 'trying') return;
              music.play('breakpoint');
            },
            onUpdate: () => {
              canvas.setBrokenImageProgress(getProgressValue(canvas, progress));
            },
            onComplete: () => {
              progress.value = 0;
            }
          }
        )
      ;
    }
  },
  timeline: ({ slide, canvas, music, container, id }) => {
    const brokenTimeline = slide.getLines();
    const progress = {
      value: 0
    };
    const linesProgress = brokenTimeline.map(() => ({ value: 0 }));

    played = false;

    const tl = gsap
      .timeline({
        paused: true
      })
      .set(progress, { value: 0 })
      .set(
        linesProgress,
        {
          value: 0,
          onStart: () => {
            if (music.name !== 'trying') music.play('breakpoint');

            const timeout = played ? 1000 : 200;

            setTimeout(() => {
              if (played) {
                played = false;

                music.playTrack('breakingLine');
                window.analytics.trackPage('breakpoint', `${tags.pages.story[id].subpage}${tags.pages.breakpoint}2`);
              } else {
                music.playTrack('breaking');
                played = true;
                window.analytics.trackPage('breakpoint', `${tags.pages.story[id].subpage}${tags.pages.breakpoint}`);
              }
            }, timeout);
          }
        }
      )
    ;

    brokenTimeline.forEach((item, index) => {
      tl.add(
        gsap.to(
          linesProgress[index],
          0.5,
          {
            value: 1,
            ease: 'expo.in',
            onUpdate: () => updateLineValue(slide.getLines()[index], linesProgress[index].value, index ? 55 : 110)
          },
          0
        ),
        index ? '-=0.01' : 0.8
      );
    });

    const texts = [...container.querySelectorAll(CLASSES.item)];
    const animatedTexts = gsap
      .timeline({
        paused: true
      })
        .fromTo(texts[0], 1, { y: -20, autoAlpha: 0, scale: 0.96 }, { y: 0, autoAlpha: 1, scale: 1 })
    ;
    if (texts[1]) animatedTexts.add(gsap.fromTo(texts[1], 1, { autoAlpha: 0 }, { autoAlpha: 1 }), '+=0.2');

    tl
      .add(animatedTexts.play(0), 2.5)
      .to(
        progress,
        1.4,
        {
          value: 1,
          ease: 'power4.out',
          onUpdate: () => {
            canvas.setBrokenImageProgress(getProgressValue(canvas, progress));
          },
          onComplete: () => {
            progress.value = 0;
          }
        },
        1
      )
    ;

    return tl;
  }
};

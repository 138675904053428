import gsap from 'gsap';

import CONSTS from '../../consts';

const { tags } = CONSTS;

const CLASSES = {
  scrollbar: '.js-perfect-scrollbar'
};

const progress = {
  x: 0
};

const lerp = (a,b,t) => a * (1 - t) + b * t;

const flatLine = (slide) => {
  let line = slide.getLines()[0];
  let start = line.geometry.attributes.instanceStart;

  let myar = line.geometry.getPositions(start.data.array);
  let art = [];
  let min = myar[0];
  let max = myar[myar.length - 3];
  let len = myar.length;

  for (let i = 0; i <= len; i += 3) {
    art.push(lerp(min, max, i / len), 0, 0)
  }
  const o = { p: 0 };

  return gsap.fromTo(
    o,
    1,
    {
      p: 0
    },
    {
      p: 1,
      onStart() {
        if (this.reversed()) return;

        art = [];
        /* eslint-disable */
        line = slide.getLines()[0];
        start = line.geometry.attributes.instanceStart;
        myar = line.geometry.getPositions(start.data.array);
        min = myar[0];
        max = myar[myar.length - 3];
        len = myar.length;
        /* eslint-enable */

        for (let i = 0; i <= len; i += 3) {
          art.push(lerp(min, max, i / len), 0, 0)
        }

        o.p = 0;
      },
      onUpdate:() => {
        const ar = [];
        for (let i = 0; i < len; i++) {
          ar.push(lerp(myar[i], art[i], o.p));
        }
        if (line) line.geometry.setPositions(ar);
      }
    }
  );
}

export default {
  name: 'history',
  reverse: {
    forwards: ({ slide, container, music }) => {
      const ps = container.querySelector(CLASSES.scrollbar);

      const lineProgress = {
        x: 0
      };

      return gsap
        .timeline({
          paused: true
        })
          .to(ps, 1, { scrollTop: 10 })
          .to(
            lineProgress,
            1,
            {
              x: 0.7,
              onStart: () => {
                music.playTrack('text');
              },
              onUpdate: () => {
                const line = slide.getLines()[0];
                line.geometry.maxInstancedCount = lineProgress.x * 100;
              },
              onComplete: () => { lineProgress.x = 0; }
            },
          '-=1'
        )
      ;
    },
    reverse: ({ slide, canvas, music, container }) => {
      const ps = container.querySelector(CLASSES.scrollbar);

      const scroll = {
        x: 0
      };

      const lineOptions = {
        progress: 1
      };

      return gsap
        .timeline({
          paused: true
        })
        .to(
          progress,
          1,
          {
            x: 2,
            onUpdate: () => canvas.setBrokenImageProgress(progress.x),
            onStart: () => {
              lineOptions.progress = 1;
            }
          }
        )
        .add(flatLine(slide).reverse())
        .to(
          lineOptions,
          1,
          {
            progress: 0.7,
            ease: 'power4.out',
            onUpdate: () => {
              const line = slide.getLines()[0];
              line.geometry.maxInstancedCount = lineOptions.progress * 100;
            },
            onStart: () => {
              music.playTrack('sinToLine');
            }
          }
        )
        .fromTo(scroll, 1, { x: () => ps.offsetHeight }, { x: 10, ease: 'power4.out', onUpdate: () => { ps.scrollTop = scroll.x } }, '-=0.2')
      ;
    }
  },
  beforeEnd: ({ slide, music }) => {
    const lineProgress = {
      value: 0.7
    };

    return gsap
      .timeline({
        paused: true
      })
      .add(flatLine(slide))
      .to(
        lineProgress,
        0.8,
        {
          value: 0.8,
          onStart: () => {
            music.playTrack('sinToLine');
          },
          onUpdate: () => {
            const line = slide.getLines()[0];
            line.geometry.maxInstancedCount = lineProgress.value * 100;
          },
          onComplete: () => {
            lineProgress.value = 0.7;

            const line = slide.getLines()[0];
            line.geometry.maxInstancedCount = 100;
          }
        }
      )
    ;
  },
  timeline: ({ slide, music, container, id }) => {
    const ps = container.querySelector(CLASSES.scrollbar);

    const lineProgress = {
      value: 0
    };

    return gsap
      .timeline({
        paused: true
      })
      .to(ps, 1, { scrollTop: 10 })
      .fromTo(
        lineProgress,
        1,
        { x: 0 },
        {
          x: 0.7,
          onStart: () => {
            music.play('text');
            music.playTrack('text');
            window.analytics.trackPage('verticalText', `${tags.pages.story[id].subpage}${tags.pages.text}`);
          },
          onUpdate: () => {
            const line = slide.getLines()[0];
            line.geometry.maxInstancedCount = lineProgress.x * 100;
          },
          onComplete: () => { lineProgress.value = 0; }
        },
        '-=0.2'
      )
    ;
  }
};

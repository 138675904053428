/* eslint-disable */
export default class Utils {
  
  updateViewportUnits() {
 
      document.body.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
      document.body.style.setProperty('--vw', `${window.innerWidth * 0.01}px`);
    }
  

  isString(item) {
    return typeof item === 'string';
  }

  getWheelEvent() {
    return 'onwheel' in document ? 'wheel' : document.onmousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll';
  }
}
/* eslint-enable */

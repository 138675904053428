import gsap from 'gsap';

import CONSTS from '../../consts';

const { tags } = CONSTS;

const CLASSES = {
  text: '.js-hysterics-text',
  path: '.js-hysterics-path',
  mom: '.js-hysterics-mom',
  svg: '.js-hysterics-svg svg'
};

export default {
  name: 'hysterics',
  reverse: {
    forwards: ({ music }) => ({
      play() {
        music.play('hysterics');
      }
    }),
    reverse: ({ music }) => ({
      play() {
          music.play('hysterics');
      }
    })
  },
  timeline: ({ canvas, music, container, id }) => {
    const brokenImageProgress = { value: 1 };

    const el = {
      video: container.querySelector(CLASSES.mom),
      txt: container.querySelector(CLASSES.text),
      svg: container.querySelector(CLASSES.svg),
      path: container.querySelector(CLASSES.path),
    };

    const isSebastianStory = id === 'second';

    return gsap
      .timeline({
        paused: true
      })
      .to(
        brokenImageProgress,
        1.2,
        {
          value: 1,
          onStart: () => {
            music.play('hysterics');
            window.analytics.trackPage('hysterics', `${tags.pages.story[id].subpage}${tags.pages.hysterics}0`);
            if (isSebastianStory) music.playTrack('hysterics');
          },
          onUpdate: () => canvas.setBrokenImageProgress(brokenImageProgress.value)
        },
        0
      )
      .to(
        el.path,
        0.5,
        {
          strokeDashoffset: 1200,
          ease: 'power4.out',
          onStart: () => {
            setTimeout(() => music.playTrack('hystericsLine'), 100);
          }
        },
        '-=0.2'
      )
      .fromTo(
        el.video,
        0.5,
        { x: window.innerWidth },
        { x: window.innerWidth * 0.51 },
        '-=0.4'
      )
      .to(el.path, 2, { strokeDashoffset: 0, ease: 'power4.out' }, '-=0.1')
      .to(el.svg, 1, { x: -window.innerWidth })
      .to(el.video, 1, { x: 0 }, '-=1')
      .fromTo(
        el.txt,
        1,
        { autoAlpha: 0 },
        { autoAlpha: 1 }
      )
    ;
  }
};

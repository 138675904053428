/* eslint-disable */
import * as THREE from "three";
import * as dat from "dat.gui";

window.THREE = THREE;

import map from '../../lib/map'

import gsap from 'gsap';

import fragment from "./shader/fragment.glsl";
import vertex from "./shader/vertex.glsl";
import fragment1 from "./shader/fragment1.glsl";
import vertex1 from "./shader/vertex1.glsl";
import Item from "./Item";
import Point from "./Point";

import { Line2 } from './lines/Line2';
import { LineMaterial } from './lines/LineMaterial';
import { LineGeometry } from './lines/LineGeometry';


var bezier = require('adaptive-bezier-curve');
var Line = require('three-line-2d')(THREE);
var BasicShader = require('three-line-2d/shaders/basic')(THREE);
const noise = '/img/noise.png';

const OrbitControls = require('three-orbit-controls')(THREE);

const MAX = 100;

export default class Canvas {
  constructor({
    container
  }) {
    this.scroll = {
      pos: {
        x: 0,
        y: 0
      }
    };

    this.classes = {
      images: '.js-canvas-img',
      dots: '.js-dot',
      parallax: '.js-canvas-parallax-img'
    };

    this.isGalleryMoving = false;

    this.dots = [];
    this.images = [];
    this.parallaxImages = [];

    this.container = document.querySelector(container);

    this.scene = new THREE.Scene();
    window.scene = this.scene;
    this.renderer = new THREE.WebGLRenderer({
      antialias: true
    });

    this.width = window.innerWidth;
    this.height = window.innerHeight;
    // this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setPixelRatio(1);
    this.renderer.setSize(this.width, this.height);
    // this.renderer.setClearColor(0x00ff00, 1);
    this.renderer.setClearColor(0x000000, 1);

    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.container.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );

    this.cameraDistance = 800;
    this.camera.position.set(0, 0, this.cameraDistance);
    this.camera.lookAt(0, 0, 0);
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.time = 0;

    this.paused = false;
    this.group = new THREE.Group()
    this.parallax = new THREE.Group()
    this.images = new THREE.Group()
    this.scene.add( this.group );
    this.scene.add( this.parallax );
    this.scene.add( this.images );
    this.raycaster = new THREE.Raycaster();
    this.currentLines = [];
    this.mousePoint = new THREE.Vector2(-1000,-1000);
    this.lines = [];

    this.testURL = '/img/test.png';

    this.brokenImageDeviation= -0.0005;
  }

  mouseEvent(){
    let that = this;
    this.mouse = new THREE.Vector2();

    function onMouseMove( event ) {
      that.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
      that.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
      // update the picking ray with the camera and mouse position
      that.raycaster.setFromCamera( that.mouse, that.camera );
      // calculate objects intersecting the picking ray
      var intersects = that.raycaster.intersectObjects( [that.plane] );
      if(intersects.length){
        // that.line.material.mouse = intersects[0].point;
        that.mousePoint.x = intersects[0].point.x - that.scroll.pos.x;
        that.mousePoint.y = intersects[0].point.y
      }
    }
    window.addEventListener( 'mousemove', onMouseMove, false );
  }

  setGalleryMovement() {
    this.isGalleryMoving = true;
  }

  init() {
    if (!this.container) return;

    this.imageMesh();
    this.createSmoke();
    this.resize();
    this.render();
    this.settings();
    this.mouseEvent();
  }


  settings() {
    this.settings = {
      progress: 0,
      lineProgress: 0,
      brokenImageProgress: this.brokenImageDeviation,

      createLine: () => {
        let dots = [];
        let precision = 100;
        for (let i = 0; i < 10; i++) {
          dots.push({left:800*Math.random(),top:800*Math.random()})
        }
        this.createNewLine(dots,precision,false);
      },

      createParallax: () => {
        this.createParallax();
      },


      removeParallax: () => {
        this.removeParallax();
      },

      createBrokenImage: () => {
        this.createBrokenImage();
      },

      removeBrokenImage: () => {
        this.removeBrokenImage();
      },

      createImages: () => {
        this.createImages();
      },

      removeImages: () => {
        this.removeImages();
      },

      removeTimeline: () => {
        this.removeTimeline();
      },

      removeBrokenTimeline: () => {
        this.removeBrokenTimeline();
      },
      smokeMove: () => {
        this.smokeMove();
      }
    };

    // this.gui = new dat.GUI();

    // this.gui.add(this.settings, "progress", 0, 1, 0.01);
    // this.gui.add(this.settings, "lineProgress", 0, 1, 0.01);
    // this.gui.add(this.settings, "brokenImageProgress", -1, 1, 0.00000001);
    // this.gui.add(this.settings, "createLine");
    // this.gui.add(this.settings, "createParallax");
    // this.gui.add(this.settings, "removeParallax");
    // this.gui.add(this.settings, "createBrokenImage");
    // this.gui.add(this.settings, "removeBrokenImage");
    // this.gui.add(this.settings, "createImages");
    // this.gui.add(this.settings, "removeImages");
    // this.gui.add(this.settings, "removeTimeline");
    // this.gui.add(this.settings, "removeBrokenTimeline");
    // this.gui.add(this.settings, "smokeMove");
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;

    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;
    this.plane.scale.set(this.width, this.height ,1)

    // image cover
    this.imageAspect = 1;
    let a1; let a2;
    if(this.height/this.width>this.imageAspect) {
      a1 = (this.width/this.height) * this.imageAspect ;
      a2 = 1;
    } else{
      a1 = 1;
      a2 = (this.height/this.width) / this.imageAspect;
    }
    this.materialFog.uniforms.resolution.value.x = this.width;
    this.materialFog.uniforms.resolution.value.y = this.height;
    this.materialFog.uniforms.resolution.value.z = a1;
    this.materialFog.uniforms.resolution.value.w = a2;


    this.camera.fov = 2 * Math.atan(this.width / this.camera.aspect / (2 * this.cameraDistance)) * (180 / Math.PI);
    this.camera.updateProjectionMatrix();

    if (this.items) this.items.forEach(item => item.resize());
  }

  createNewLine(dots,precision,movable,visibleAll,physics,secondTimelineLines,broken){

    const lines = secondTimelineLines
      ? secondTimelineLines.map(item => this.createNewLine(item, precision / 2, movable, visibleAll, physics, false, broken))
      : []
    ;

    let newline, o = {},debugMeshes = [];
    const matLine = new LineMaterial( {
      color: 0x666666,
      linewidth: 0.002,
      dashed: false
    } );
    const positions = [];
    let dotsArray = dots.map(dot=>{
      return new THREE.Vector2(
        dot.left - this.width/2,
        -dot.top + this.height/2
       )
    })
    let points = new THREE.SplineCurve(dotsArray).getPoints( precision );


    let simPoints = [];
    let len = dotsArray.length;
    points.forEach((p,i)=>{
      positions.push(p.x,p.y,0)

      let staticness = (i<1) || (i>precision-2)
      // check if its close to dotsArray
      for (let j = 0; j < len; j++) {
        if(Math.sqrt(  (p.x - dotsArray[j].x)**2 +  (p.y - dotsArray[j].y)**2    )<50){
          staticness = true;
        }
      }
      simPoints.push(new Point(p.x,p.y, staticness));

      // debug
      // if(physics){
      //   let t = new THREE.Mesh(new THREE.PlaneGeometry(5,5),new THREE.MeshBasicMaterial({color:0xff0000}))
      //   t.position.x = p.x;
      //   t.position.y = p.y;
      //   this.group.add(t);
      //   debugMeshes.push(t);
      // }
      // debug
    });
    simPoints.forEach(p=>{
      p.getNeighbours(simPoints);
    })
    newline = new Line2( new LineGeometry().setPositions( positions ), matLine );
    o.mesh = newline;
    o.physics = simPoints;
    o.debug = debugMeshes;
    if(broken){o.broken = true;}
    if(physics){
      this.lines.push(o);
    }

    if(movable){
      this.group.add( newline );
    } else{
      this.scene.add( newline );
    }

    if(!visibleAll) this.currentLines.push(newline);
    return lines.length ? [newline, ...lines] : newline;
  }

  removeLine(object){
    object.geometry.dispose();
    object.material.dispose();
    this.scene.remove( object );

    this.currentLines.filter(el=>{
       return el.uuid !== object.uuid;
    })
    this.lines.filter(el=>{
       return el.mesh.uuid !== object.uuid;
    })
  }

  removeTimeline() {
    this.scene.remove(this.timeline);
  }

  createBrokenTimeline(dots) {
    const dotsA = [];
    const dotsA1 = [];

    let flag = false;

    dots.forEach(dot=>{
      const dot1 = {
        left: dot.offsetLeft,
        top: dot.offsetTop
      };

      if (flag){
        flag = false;
        dotsA1[dotsA1.length - 1].push(dot1)
      } else{
        dotsA.push(dot1)
      }

      if (dot.classList.value.indexOf('js-breakme') !== -1) {
        dotsA1.push([dot1]);
        flag = true;
      };
    });

    if (dots.length) {
      return this.createNewLine(dotsA, MAX * 1.1, false, true, false, dotsA1, true);
    }
  }

  imageMesh() {
    this.geometryImage = new THREE.PlaneBufferGeometry(1, 1, 80, 80);
    // fotos
    this.materialImage = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { type: "f", value: 0 },
        mouseover: { type: "f", value: 0 },
        mouseDir: { type: "v2", value: new THREE.Vector2() },
        progress: { type: "f", value: 0 },
        angle: { type: "f", value: 0 },
        texture1: { type: "t", value: null },
        texture2: { type: "t", value: null },

        resolution: { type: "v4", value: new THREE.Vector4() },
        uvRate1: {
          value: new THREE.Vector2(1, 1)
        }
      },
      // wireframe: true,
      transparent: true,
      vertexShader: vertex1,
      fragmentShader: fragment1
    });
    // fog, bg
  }



  createSmoke() {
    const noizeTexture = new THREE.TextureLoader().load(noise);
    noizeTexture.wrapS = noizeTexture.wrapT = THREE.MirroredRepeatWrapping;

    const bgTexture = new THREE.TextureLoader().load('/img/back.jpg');
    bgTexture.wrapS = bgTexture.wrapT = THREE.MirroredRepeatWrapping;

    this.materialFog = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { type: "f", value: 0 },
        offset: { type: "f", value: 0 },
        resolution: { type: "v4", value: new THREE.Vector4() },
        noiseTexture: { type: "t", value: noizeTexture },
        bg: { type: "t", value: bgTexture },
        // bg: { type: "t", value: new THREE.TextureLoader().load('/img/broke.png') },
        uvRate1: {
          value: new THREE.Vector2(1, 1)
        }
      },
      // wireframe: true,
      // transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment
    });

    this.geometry = new THREE.PlaneGeometry(1.05, 1.05, 1, 1);

    this.plane = new THREE.Mesh(this.geometry, this.materialFog);
    this.plane.position.z = -6
    this.scene.add(this.plane);
    this.plane.scale.set(this.width,this.height,1)
  }

  smokeMove(offset = 1){
    gsap.to(
      this.materialFog.uniforms.offset,
      {
        duration: 1,
        value: this.materialFog.uniforms.offset.value + offset
      }
    );
  }

  stop() {
    this.paused = true;
  }

  play() {
    this.paused = false;
    this.render();
  }

  removeTimeline() {
    this.group.remove(this.timeline);
  }

  getParallax() {
    return this.parallax;
  }

  getTimeline() {
    return this.timeline;
  }

  getImages() {
    return this.images;
  }

  createTimeline(dots) {
    this.removeTimeline();
    this.timeline = this.createNewLine(dots, MAX, true, true, false);
  }

  removeImages() {
    if (this.items) {
      this.items.forEach(item => this.images.remove(item.getMesh()));
      this.items = [];
    }
  }

  getImagesModules(images, container) {
    return images.map((image) => {
      const item = new Item(image, container, this);
      const len = Math.sqrt((item.width / 2) ** 2 + (item.height / 2) ** 2);

      image.addEventListener('mousemove', (e) => {
        const m = {
          x: (-item.width / 2 + e.offsetX) / len,
          y: (item.height / 2 - e.offsetY) / len
        };
        item.mesh.material.uniforms.mouseDir.value = new THREE.Vector2(m.x, m.y);
      });

      image.addEventListener('mouseover', () => {
        gsap.to(item.mesh.material.uniforms.mouseover,{
          duration: 1,
          value: 1
        })
      });

      image.addEventListener('mouseout', () => {
        gsap.to(item.mesh.material.uniforms.mouseover,{
          duration: 1,
          value: 0
        })
      });

      image.style.opacity = 0;

      return item;
    });
  }

  addImages(images, container) {
    this.items = this.getImagesModules(images, container);

    this.items.forEach(item => this.images.add(item.getMesh()));
    this.group.add(this.images);
  }

  getImages() {
    return [...document.querySelectorAll(this.classes.images)];
  }

  resizeImages() {
    if (this.items && this.items.length) this.items.forEach(item => item.resize());
  }

  createImages(container) {
    this.removeImages();

    const images = this.getImages();
    const isRendered = images.every(item => item.offsetWidth && item.offsetHeight);

    if (!isRendered) {
      setTimeout(() => {
        this.createImages();
        this.createParallax();
      }, 100);
      return;
    }

    setTimeout(() => {
      this.addImages(images, container);
    }, 1000);
  }

  createMesh(o) {
    let material = this.materialImage.clone();
    let texture = new THREE.Texture(o.image);
    texture.needsUpdate = true;
    // image cover
    let imageAspect = o.iHeight / o.iWidth;
    let a1;
    let a2;
    if (o.height / o.width > imageAspect) {
      a1 = (o.width / o.height) * imageAspect;
      a2 = 1;
    } else {
      a1 = 1;
      a2 = o.height / o.width / imageAspect;
    }
    texture.minFilter = THREE.LinearFilter;
    material.uniforms.resolution.value.x = o.width;
    material.uniforms.resolution.value.y = o.height;
    material.uniforms.resolution.value.z = a1;
    material.uniforms.resolution.value.w = a2;
    material.uniforms.progress.value = 0;
    material.uniforms.angle.value = 0.3;

    material.uniforms.texture1.value = texture;
    material.uniforms.texture1.value.needsUpdate = true;

    let mesh = new THREE.Mesh(this.geometryImage, material);
    mesh.position.z = 5;

    mesh.scale.set(o.width, o.height, 1);

    return mesh;
  }

  removeParallax() {
    this.scene.remove(this.parallax);
  }

  createParallax() {
    const images = [...document.querySelectorAll(this.classes.parallax)];
    this.parallax.children = [];
    this.removeParallax();

    this.parallaxImages = images.map((item) => {
      const bounds = item.getBoundingClientRect();

      const texture = new THREE.TextureLoader().load(item.src);
      const m = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true
      });
      const g = new THREE.PlaneGeometry(bounds.width, bounds.height);
      const mesh = new THREE.Mesh(g, m);

      mesh.position.x = 0 - this.width / 2 + bounds.left + bounds.width / 2;
      mesh.position.y = this.height / 2 - bounds.top - bounds.height / 2;

      this.parallax.add(mesh);

      return mesh;
    });

    this.scene.add(this.parallax);
  }

  remove(mesh) {
    this.scene.remove(mesh);
  }

  setBrokenImageOpacity(value) {
    if (!(this.pieces && this.pieces.length)) return;
    this.pieces.forEach(({ mesh }) => mesh.material.opacity = value);
  }

  getBrokenImageProgress() {
    return this.settings.brokenImageProgress;
  }

  setBrokenImageProgress(progress) {
    this.settings.brokenImageProgress = progress + this.brokenImageDeviation;
  }

  removeBrokenImage() {
    this.scene.remove(this.brokenImage);
  }

  containTexture({ width, height }) {
    const {
      innerHeight,
      innerWidth
    } = window;

    const widthRatio = innerWidth / width;
    const heightRatio = innerHeight / height;

    return Math.max(widthRatio, heightRatio);
  }

  createBrokenImage(src, type = 'first'){
    this.brokenImage = new THREE.Group();
    // get geometries

    const w = 1183;
    const h = 735;

    const t = new THREE.TextureLoader().load(src, () => {
      const scale = this.containTexture({
        width: t.image.width,
        height: t.image.height
      });

      const currentScale = scale > 1.5 ? 1.5 : scale;

      this.brokenImage.scale.set(currentScale, currentScale, currentScale);
      this.brokenImage.position.y = -(window.innerHeight - t.image.height) * Math.abs(currentScale - 0.6) * 0.5;
    });

    t.wrapS = t.wrapT = THREE.RepeatWrapping;
    t.repeat.set( 1 / w, 1 / h );
    t.offset.set( 0.5, 0.5 );

    const material = new THREE.MeshBasicMaterial({ map: t, transparent: true });
    material.opacity = 0;
    this.pieces = [];

    function getMeshFromPoints(points) {
      const shape = new THREE.Shape();
      shape.moveTo(points[0].x,points[0].y)
      points.forEach(p => shape.lineTo( p.x, p.y ));
      shape.lineTo(points[0].x,points[0].y)
      const extrudeSettings = {
        depth: 0,
        bevelEnabled: false
      };
      const geometry = new THREE.ExtrudeBufferGeometry( shape, extrudeSettings );

      return new THREE.Mesh( geometry, material );
    };

    const psa = ({
      second: [
        [
          new THREE.Vector2(500 -w/2,h/2),
          new THREE.Vector2(242 -w/2,h/2),
          new THREE.Vector2(-w/2,h/2 - 500),
          new THREE.Vector2(620 - w/2,h/2 - 500),
        ],
        [
          new THREE.Vector2(500 -w/2,h/2),
          new THREE.Vector2(w/2,h/2),
          new THREE.Vector2(w/2,h/2 - 420),
          new THREE.Vector2(625.7 - w/2, h/2 - 520),
        ],
        [
          new THREE.Vector2(660 - w/2, h/2 - 514.5),
          new THREE.Vector2(w/2, h/2 - 420),
          new THREE.Vector2(w/2, h/2 - 620),
        ],
        [
          new THREE.Vector2(625.7 - w/2, h/2 - 520),
          new THREE.Vector2(660 - w/2, h/2 - 514.5),
          new THREE.Vector2(w/2, h/2 - 620.1),
          new THREE.Vector2(w/2, -h/2),
          new THREE.Vector2(500 -w/2, -h/2),
        ],
        [
          new THREE.Vector2(400 -w/2, h/2 - 500.6),
          new THREE.Vector2(620.1 - w/2, h/2 - 500.6),
          new THREE.Vector2(625.7 - w/2, h/2 - 520),
          new THREE.Vector2(500 -w/2, -h/2),
          new THREE.Vector2(320 -w/2, -h/2),
        ],
        [
          new THREE.Vector2(- w/2, h/2 - 500.6),
          new THREE.Vector2(399.5 -w/2, h/2 - 500.6),
          new THREE.Vector2(319.5 -w/2, -h/2),
          new THREE.Vector2(-w/2, -h/2),
        ],
      ],
      first: [
        [
          new THREE.Vector2(-w * 0.5, h * 0.5),
          new THREE.Vector2(240 - w * 0.5,h/2),
          new THREE.Vector2(412.5 -w/2,h/2 - 400),
          new THREE.Vector2( -w/2,h/2 - 506),
        ],
        [
          new THREE.Vector2(605.5 -w/2,h/2),
          new THREE.Vector2(242 -w/2,h/2),
          new THREE.Vector2(412 -w/2,h/2 - 400),
          new THREE.Vector2(572.5 -w/2,h/2 - 348),
        ],
         [
          new THREE.Vector2(412 -w/2,h/2 - 400),
          new THREE.Vector2( -w/2,h/2 - 506),
          new THREE.Vector2( -w/2,h/2 - 710),
          new THREE.Vector2( 409-w/2,h/2 - 561),
        ],
        [
          new THREE.Vector2( -w/2,h/2 - 710),
          new THREE.Vector2( 409-w/2,h/2 - 561),
          new THREE.Vector2( 535-w/2,-h/2),
          new THREE.Vector2( -w/2,-h/2),
        ],
        [
          new THREE.Vector2(572 -w/2,h/2 - 348),
          new THREE.Vector2(412 -w/2,h/2 - 400),
          new THREE.Vector2( 409-w/2,h/2 - 561),
          new THREE.Vector2( 535-w/2,-h/2),
        ],
        [
          new THREE.Vector2(1005 -w/2,h/2),
          new THREE.Vector2(606 -w/2,h/2),
          new THREE.Vector2(535-w/2,-h/2),
        ],
        [
          new THREE.Vector2( 535-w/2,-h/2),
          new THREE.Vector2( w/2 - 100,-h/2),
          new THREE.Vector2( w/2,207-h/2),
          new THREE.Vector2(660.5- w/2,-540+h/2),
        ],
        [
          new THREE.Vector2(1006 -w/2,h/2),
          new THREE.Vector2( w/2,h/2),
          new THREE.Vector2( w/2,208-h/2),
          new THREE.Vector2( 660- w/2,-540+h/2),
        ],
      ]
    }[type]);

    this.brokenPieces = psa.map(ps => {
      let x = 0;
      let y = 0;

      ps.forEach(p => {
        x += p.x;
        y += p.y;
      });

      x = x / ps.length;
      y = y / ps.length;

      const centroid = new THREE.Vector3(x, y, 0)

      const mesh = getMeshFromPoints( ps, this );
      mesh.position.z = -4;
      mesh.material.opacity = 0;

      this.pieces.push({
        centroid,
        mesh,
        random: Math.random()
      });

      this.brokenImage.add(mesh);
      return mesh;
    });

    this.scene.add(this.brokenImage);
  }

  setScrollPos(x, y) {
    this.scroll.pos.x = x;
    this.scroll.pos.y = y;
  }

  setCanvasObjectsPos(x) {
    this.group.position.x = x;
    this.parallax.position.x = x;
  }

  render() {
    if (this.paused) return;
    this.time += 0.05;

    if(this.group && this.isGalleryMoving) {
      this.group.position.x = this.scroll.pos.x;
      this.parallax.position.x = this.scroll.pos.x / 2;
    }

    if(this.pieces && this.pieces.length){
      this.pieces.forEach(o => {
        o.mesh.scale.set(0.5 * (2 - this.settings.brokenImageProgress), 0.5 * (2 - this.settings.brokenImageProgress), 1);
        const scaleX = 0.5 * (2 - this.settings.brokenImageProgress) || 0 + this.brokenImageDeviation;
        const scaleY = 0.5 * (2 - this.settings.brokenImageProgress) || 0 + this.brokenImageDeviation;
        const scaleZ = 1;

        o.mesh.scale.set(scaleX, scaleY, scaleZ);
        o.mesh.rotation.z = 0.5 * (o.random - 0.5) * this.settings.brokenImageProgress;
        o.mesh.position.x = o.centroid.x * this.settings.brokenImageProgress * 3;
        o.mesh.position.y = o.centroid.y * this.settings.brokenImageProgress * 3;
      });
    }

    this.lines.forEach(lineObj=>{
      let pos = [];
      let poss = [];

      lineObj.physics.forEach((point, i)=>{
        point.update(this.mousePoint);
        // lineObj.debug[i].position.x = point.pos[0];
        // lineObj.debug[i].position.y = point.pos[1];
        poss.push( new THREE.Vector2(point.pos[0],point.pos[1]))
        pos.push( point.pos[0],point.pos[1],0)
      })
      let p = []
      let number = lineObj.broken? 140:440;
      let points = new THREE.SplineCurve(poss).getPoints( number );
       points.forEach(pp=>{
         p.push(pp.x,pp.y,0);
       })
      lineObj.mesh.geometry.setPositions(p);

    });

    // this.currentLines.forEach(line=>{
    //   line.geometry.maxInstancedCount = 100*this.settings.lineProgress;
    // })
    this.materialFog.uniforms.time.value = this.time;

    this.renderer.render(this.scene, this.camera);

    // window.requestAnimationFrame(this.render.bind(this));
  }

};

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getHoverItemAudioId", "isAudioUnlocked", "getKey"] }] */

import { Howler, Howl } from 'howler';

const sounds = {
  mom: {
    in: './audio/sounds/intro/2_mother_title.mp3',
    out: './audio/sounds/intro/3_mother_end.mp3'
  },
  photo: {
    in: './audio/sounds/4_photo.mp3'
  },
  airplane: {
    in: './audio/sounds/5_airplane.mp3'
  },
  tiger: {
    in: './audio/sounds/5_tiger.mp3'
  }
};

export default class Sounds {
  constructor() {
    this.classes = {
      item: '[data-hover-sound]'
    };

    this.states = {
      enter: 'in',
      leave: 'out',
      loaded: 'loaded'
    };

    this.sounds = [];

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  play(id, state) {
    window.clearTimeout(this.timeout);

    if (!this.isAudioUnlocked()) return;

    const current = this.sounds[id][state];
    if (!current) return;

    if (current && (state === this.states.leave) && this.sounds[id] && this.sounds[id][this.states.enter].playing()) return;
    if (current.playing() && id !== 'photo') return;

    if (current.state() !== this.states.loaded) current.load();

    current.stop();
    current.play();
  }

  getKey(item) {
    const { hoverSound, hoverId } = item.dataset;
    return hoverId ? hoverSound + hoverId : hoverSound;
  }

  getHoverItemAudioId(el) {
    return el.dataset.hoverSound;
  }

  handleMouseLeave({ currentTarget }) {
    const id = this.getKey(currentTarget);
    this.play(id, this.states.leave);
  }

  handleMouseEnter({ currentTarget }) {
    const id = this.getKey(currentTarget);
    this.play(id, this.states.enter);
  }

  initHandlers() {
    this.items.forEach((item) => {
      item.addEventListener('mouseenter', this.handleMouseEnter);
      item.addEventListener('mouseleave', this.handleMouseLeave);
    });
  }

  initValues() {
    this.items = [...document.querySelectorAll(this.classes.item)];

    this.sounds = this.items.reduce((result, item) => {
      const id = this.getHoverItemAudioId(item);
      const key = this.getKey(item);

      const obj = result;
      obj[key] = {};

      if (sounds[id][this.states.enter]) {
        obj[key].in = new Howl({
          src: sounds[id][this.states.enter],
          loop: false,
          autoplay: false,
          preload: true
        });
      }

      if (sounds[id][this.states.leave]) {
        obj[key].out = new Howl({
          src: sounds[id][this.states.leave],
          loop: false,
          autoplay: false,
          preload: true
        });
      };

      return obj;
    }, {});
  }

  isAudioUnlocked() {
    const { _audioUnlocked } = Howler;
    return _audioUnlocked;
  }

  destroy() {
    if (!this.items) return;

    this.items.forEach((item) => {
      item.removeEventListener('mouseenter', this.handleMouseEnter);
      item.removeEventListener('mouseleave', this.handleMouseLeave);
    });

    this.items = [];
  }

  init() {
    this.destroy();

    this.initValues();
    this.initHandlers();
  }
};

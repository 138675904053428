import gsap from 'gsap';

import CONSTS from '../../consts';

const { tags } = CONSTS;

const CLASSES = {
  container: '.js-video-360',
  control: '.js-video-control',
  progress: '.js-progress'
};

export default {
  name: 'video',
  reverse: {
    forwards: ({ music }) => gsap
      .timeline({
        paused: true
      })
      .to(
        [CLASSES.progress],
        1,
        {
          autoAlpha: 0,
          onStart: () => {
            music.setState(true);
            music.changeVolume(0, () => music.pauseAll());
          }
        },
        '-=1'
      )
    ,
    reverse: ({ music, canvas }) => gsap
      .timeline({
        paused: true
      })
      .to(
        [CLASSES.progress],
        1,
        {
          autoAlpha: 0,
          onStart: () => {
            music.setState(true);
            music.changeVolume(0, () => music.pauseAll());
          },
          onComplete: () => {
            canvas.setBrokenImageOpacity(0.3); 
          }
        },
        '-=1'
      )
  },
  timeline: ({ video, music, id }) => gsap
    .timeline({
      paused: true,
    })
      .fromTo(
        CLASSES.container,
        2,
        { autoAlpha: 0, x: window.innerWidth },
        {
          autoAlpha: 1,
          x: 0,
          onStart: () => {
            music.setState(true);
            music.changeVolume(0, () => music.pauseAll());
            window.analytics.trackPage('video360', `${tags.pages.story[id].subpage}${tags.pages.video}`);
          }
        }
      )
      .to([CLASSES.progress], 1, { autoAlpha: 0 }, '-=1')
      .fromTo(CLASSES.control, 1, { autoAlpha: 0 }, { autoAlpha: 1, onComplete: () => { video.rotate360(); } }, 0)
};

import gsap from 'gsap';

const CLASSES = {
  container: '[data-slide="gallery"]',
  intro: {
    btn: '.js-intro-btn'
  }
};

export default {
  name: 'gallery',
  reverse: {
    reverse: ({ canvas, music }) => {
      const progress = {
        value: 1
      };

      return gsap
        .timeline({
          paused: true
        })
        .to(
          progress,
          1,
          {
            value: 0,
            ease: 'power4.out',
            onStart: () => {
              music.play('main');
              music.setVolume(0);
              music.changeVolume(1);
            },
            onUpdate: () => canvas.setBrokenImageProgress(progress.value),
            onComplete: () => { progress.value = 1; }
          }
        )
      ;
    }
  },
  timeline: (obj) => {
    const {
      timeline,
      canvas,
      music,
      container,
      methods
    } = obj;

    const parallax = canvas.getParallax();
    const images = canvas.getImages();

    const screenWidth = window.innerWidth;

    gsap.set(CLASSES.container, { autoAlpha: 0, x: 0 });

    const tlProgress = {
      value: 0
    };

    timeline.geometry.maxInstancedCount = 0;

    return gsap
      .timeline({
        paused: true,
      })
      .set(CLASSES.container, { opacity: 0, x: 0 })
      .to(
        tlProgress,
        0.5,
        {
          value: window.innerWidth / container.scrollWidth,
          ease: 'power4.out',
          onStart: () => {
            canvas.setBrokenImageProgress(0);
            timeline.material.opacity = 1;

            music.play('main');
          },
          onUpdate: () => {
            timeline.geometry.maxInstancedCount = tlProgress.value * 100;
          },
          onComplete: () => { timeline.geometry.maxInstancedCount = 100; }
        },
        0
      )
      .fromTo(
        parallax.position,
        1.5,
        {
          x: screenWidth
        },
        {
          x: 0,
          ease: 'power4.out'
        },
        0.8
      )
      .fromTo(images.position, 1.5, { x: screenWidth }, { x: 0, ease: 'power4.out' }, 0.8)
      .to(CLASSES.intro.btn, 1, { autoAlpha: 1, onStart: () => methods.initSounds() }, 1.8)
      .to(CLASSES.container, 1, { opacity: 1 }, 1.8)
    }
  }
;

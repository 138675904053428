/*
  eslint class-methods-use-this: [
    "error",
    {
      "exceptMethods": ["before", "after", "beforeEnter", "changeVolumeState", "trackCurrentPage", "trackIntro", "handleTagTriggerClicked"]
    }
  ]
*/

import "@babel/polyfill/noConflict";

import Barba from "@barba/core";
import barbaPrefetch from "@barba/prefetch";

import _debounce from "lodash.debounce";
import gsap from "gsap";
import { CSSPlugin, CSSRulePlugin, EasePack } from "gsap/all";

gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(CSSRulePlugin);
gsap.registerPlugin(EasePack);
gsap.config({
  nullTargetWarn: false,
});

import handleUserInteraction from "./lib/CheckUserInteraction";

import Canvas from "./modules/Canvas";

import Utils from "./Utils";
import Museum from "./modules/Museum";
import Preloader from "./modules/Preloader";
import Tabs from "./modules/Tabs";
import Waves from "./canvas/Waves";
import Music from "./modules/Music";
import Accordion from "./modules/Accordion";

import AUDIO from "./media/audio";
import DATA_TO_PRELOAD from './media/data-to-preload';

import CONSTS from "./consts";
import { isEn } from "./helpers";

const { tags } = CONSTS;

class App extends Utils {
  constructor() {
    super();

    this.fetchData();

    this.classes = {
      content: ".js-content",
      loader: {
        container: ".js-loader",
        sound: ".js-sound-on",
        texts: ".js-loader-text",
      },
      home: {
        container: ".js-museum",
        title: ".js-home-title",
        call: ".js-home-call-to-scroll",
      },
      history: {
        link: ".js-history-link",
      },
      intro: {
        container: ".js-intro",
        inner: ".js-intro-inner",
        mom: {
          visible: "is-promo-visible",
        },
        btns: ".js-intro-btn",
        logo: ".js-intro-logo",
      },
      modal: {
        btn: ".js-modal-btn",
        states: {
          visible: "is-modal-visible",
        },
      },
      otherStories: {
        link: '.js-other-story-link',
      },
      tabs: ".js-tabs",
      mute: ".js-waves",
      voice: ".js-voice-btn",
      tags: ".js-tag",
      accordion: {
        wrapper: ".js-accordion-wrapp",
        item: ".js-accordion",
      },
    };

    this.data = DATA_TO_PRELOAD;

    this.body = document.body;

    this.handleScroll = this.handleScroll.bind(this);

    this.before = this.before.bind(this);
    this.after = this.after.bind(this);
    this.beforeEnter = this.beforeEnter.bind(this);

    this.handleModal = this.handleModal.bind(this);
    this.handleTagTriggerClicked = this.handleTagTriggerClicked.bind(this);
    this.handleGlobalVolumeUpdate = this.handleGlobalVolumeUpdate.bind(this);

    this.handleHistoryChanged = this.handleHistoryChanged.bind(this);
  }

  setIsFirstScreenPlayed() {
    document.body.classList.add("is-first-screen-played");
  }

  isFirstScreenPlayed() {
    return document.body.classList.value.indexOf(
      "is-first-screen-played"
    ) !== -1
  }

  handleTagTriggerClicked({ currentTarget }) {
    if (!currentTarget.dataset) return;

    const { action, type, label, category } = currentTarget.dataset;

    if (type === "page") {
      window.analytics.trackPage(action, tags.pages[action]);
    } else {
      const params = {};

      if (label && label === "history")
        params.event_label = `${label}-${
          this.museum.getPage() === "first" ? 1 : 2
        }`;
      if (category) params.event_category = category;

      window.analytics.trackAction(tags.actions[action], params);
    }
  }

  destroyTagTriggers() {
    this.triggers.forEach((item) =>
      item.removeEventListener("click", this.handleTagTriggerClicked)
    );
  }

  initTagTriggers() {
    this.triggers = [...document.querySelectorAll(this.classes.tags)];
    this.triggers.forEach((item) =>
      item.addEventListener("click", this.handleTagTriggerClicked, {
        once: true,
      })
    );
  }

  trackIntro() {
    window.analytics.trackPage("intro", tags.pages.intro);
  }

  trackCurrentPage() {
    const container = document.querySelector("[data-story]");
    if (!container) return;

    const { story } = container.dataset;
    window.analytics.trackPage(story, tags.pages.story[story].page);
  }

  before({ next }) {
    next.container.classList.add("is-fixed");

    this.museum.destroy();
    this.museum.resetValues();
    this.destroyTagTriggers();
  }

  beforeEnter({ current, next }) {
    this.museum.reinit();
    this.initModal();
    this.initTagTriggers();

    const otherStoriesDetails = current.container.dataset.barbaNamespace === 'other-stories-details';
    const allStories = current.container.dataset.barbaNamespace === 'history';
    const intro = current.container.dataset.barbaNamespace === 'intro';

    if(otherStoriesDetails || intro){
      this.generateOtherStories()
    }
    if(allStories){
      this.loadSelectedStory();
    }
  }

  after({ next }) {
    next.container.classList.remove("is-fixed");

    const nextOtherStoriesDetails =
      next.container.dataset.barbaNamespace === "other-stories-details";

    if (nextOtherStoriesDetails) {
      try {
        this.loadSelectedStory();
      } catch (e) {
        console.log("E ->", e);
      }
    }
  }

  leave({ current, next, trigger }) {
    const done = this.async();

    const { namespace: nextNamespace } = next;

    if (typeof trigger === "string") {
      gsap
        .timeline()
        .set(next.container, { autoAlpha: 0 })
        .to([current.container, this.classes.intro.btns], 1.2, { autoAlpha: 0 })
        .to(
          this.containers.intro,
          1.2,
          { autoAlpha: 1, onComplete: done },
          "-=1.2"
        );
    } else {
      gsap
        .timeline()
        .set(next.container, { autoAlpha: 0 })
        .to(this.containers.intro, 0.8, { autoAlpha: 0 })
        .to(this.classes.loader.container, 0.8, { autoAlpha: 0 }, "-=0.8")
        .to(
          this.classes.intro.btns,
          0.8,
          {
            autoAlpha: () => nextNamespace === 'history' ? 0 : 1,
            onStart: () => {
              this.modules.museum.destroySounds();
              done();
            },
          },
          "-=0.4"
        );
    }
  }

  enter({ current, next, trigger }) {
    const done = this.async();

    const isIntro = next.container.dataset.barbaNamespace === "intro";
    const isTimeline = next.container.dataset.barbaNamespace === "timeline";

    this.music.setVolume(1);

    if (isIntro) {
      this.methods.trackIntro();
    } else {
      this.methods.trackCurrentPage();
    }

    if (typeof trigger === "string") {
      const obj = {
        x: 0,
      };

      this.line.geometry.maxInstancedCount = 0;
      this.line.position.x = 0;

      gsap
        .timeline({
          onStart: () => {
            document.body.classList.add('is-forced');

            if (!this.methods.isFirstScreenPlayed()) {
              this.methods.setIsFirstScreenPlayed();
            }
          }
        })
        .set(this.classes.loader.sound, { autoAlpha: 0 })
        .to(this.classes.intro.inner, 1, { autoAlpha: () => (isIntro ? 1 : 0) })
        .to(
          obj,
          1,
          {
            x: isIntro ? 0.2 : 0,
            onUpdate: () => {
              this.line.geometry.maxInstancedCount = 100 * obj.x;
            },
          },
          "-=1"
        )
        .to(
          next.container,
          1,
          {
            autoAlpha: 1,
            onComplete: done,
          },
          "-=1"
        );
    } else {
      const obj = {
        x: 0.25,
      };

      if (isIntro) {
        gsap
          .timeline()
          .set(this.line.position, {
            x: 0,
            onStart: () => {
              this.music.setStory();
              this.music.init();
              this.music.play("intro");
            },
          })
          .to(current.container, 1.2, { autoAlpha: 0 })
          .to(this.classes.loader.sound, 1.2, { autoAlpha: 0 }, "-=1.2")
          .fromTo(
            obj,
            1.2,
            { x: 0 },
            {
              x: 1,
              onUpdate: () => {
                this.line.geometry.maxInstancedCount = 100 * obj.x;
              },
            },
            "-=1.2"
          )
          .to(obj, 1.2, {
            x: 0.2,
            onUpdate: () => {
              this.line.geometry.maxInstancedCount = 100 * obj.x;
            },
          })
          .to(
            next.container,
            1.2,
            {
              autoAlpha: 1,
              onStart: () => {
                if (!this.methods.isFirstScreenPlayed()) {
                  this.methods.handleScroll();
                }
              },
              onComplete: () => {
                gsap.to(this.classes.intro.inner, 1, { autoAlpha: 1 });
                this.modules.museum.initSounds();
                done();
              },
            },
            "-=1.2"
          )
          .to(".js-intro", 1.2, { autoAlpha: 1 });
      } else {
        if (isTimeline) {
          console.log('timeline');
          gsap.timeline()
            .to(
              obj,
              1,
              {
                x: 0,
                onUpdate: () => {
                  console.log(100 * obj.x);
                  this.line.geometry.maxInstancedCount = 100 * obj.x;
                },
              },
            )
            .to(next.container, 0.6, {
              autoAlpha: 1,
              onComplete: () => {
                gsap.set(this.line.position, { x: -window.innerWidth });
                done();
              },
            });
        } else {
          gsap.timeline()
            .to(
              obj,
              1,
              {
                x: 1,
                onUpdate: () => {
                  this.line.geometry.maxInstancedCount = 100 * obj.x;
                },
              }
            )
            .to(
              obj,
              1,
              {
                x: 0,
                onUpdate: () => {
                  this.line.geometry.maxInstancedCount = 100 * obj.x;
                },
              },
            )
            .to(next.container, 0.6, {
              autoAlpha: 1,
              onComplete: () => {
                gsap.set(this.line.position, { x: -window.innerWidth });
                done();
              },
            });
        }
      }
    }
  }

  getBarbaOptions() {
    const {
      before,
      after,
      enter,
      leave,
      beforeEnter,
      trackCurrentPage,
      trackIntro,
      museum,
      isFirstScreenPlayed,
      setIsFirstScreenPlayed,
      handleModal,
    } = this;

    const containers = {
      intro: this.intro,
    };

    const { line, classes, music } = this;

    return {
      transitions: [
        {
          name: "timeline",
          from: "intro",
          to: {
            namespace: ["timeline"],
          },
          after,
          before,
          leave,
          enter,
          containers,
          line,
          beforeEnter,
          classes,
          music,
          methods: {
            trackCurrentPage,
            trackIntro,
            isFirstScreenPlayed,
            setIsFirstScreenPlayed,
            handleModal,
          },
          modules: {
            museum,
          },
        },
        {
          name: "intro",
          from: "timeline",
          to: {
            namespace: ["intro"],
          },
          after,
          before,
          enter,
          leave,
          containers,
          line,
          classes,
          music,
          methods: {
            handleScroll: this.handleScroll,
            trackCurrentPage,
            trackIntro,
            isFirstScreenPlayed,
            setIsFirstScreenPlayed,
            handleModal,
          },
          modules: {
            museum,
          },
        },
        {
          name: "intro",
          from: "history",
          to: {
            namespace: ["intro"],
          },
          after,
          before,
          beforeEnter: () => {
            if (document.body.classList.value.includes(this.classes.modal.states.visible)) {
              this.handleModal();
            }
          },
          enter,
          leave,
          containers,
          line,
          classes,
          music,
          methods: {
            handleScroll: this.handleScroll,
            trackCurrentPage,
            trackIntro,
            isFirstScreenPlayed,
            setIsFirstScreenPlayed,
            handleModal,
          },
          modules: {
            museum,
          },
        },
        {
          name: "history",
          from: {
            namespace: ["intro"],
          },
          to: {
            namespace: ["history"],
          },
          after,
          before,
          enter,
          leave,
          beforeEnter,
          containers,
          line,
          classes,
          music,
          methods: {
            handleScroll: this.handleScroll,
            trackCurrentPage,
            trackIntro,
            isFirstScreenPlayed,
            setIsFirstScreenPlayed,
            handleModal,
          },
          modules: {
            museum,
          },
        },
        {
          name: 'default-transition',
          enter: ({ next, current }) => {
            const { namespace: nextNamespace } = next;

            gsap
              .timeline({
                onStart: () => {
                  if (nextNamespace === 'history') {
                    this.currentStoryId = null;

                    this.generateOtherStories();

                    this.music.setVolume(0);
                  } else if (nextNamespace === 'other-stories-details') {
                    this.loadSelectedStory();

                    this.music.setVolume(0);
                  }
                }
              })
              .from(current.container, 1, {
                autoAlpha: 0,
              })
              .to(next.container, 1, {
                autoAlpha: 1,
              });
          }
        }
      ],
      timeout: 3000,
    };
  }

  initBarba() {
    if (this.isBarbaInited) return;
    this.isBarbaInited = true;

    const routes = [
      {
        path: "/",
        name: "intro",
      },
      {
        path: "/story-1.html",
        name: "timeline",
      },
      {
        path: "/story-2.html",
        name: "timeline",
      },
      {
        path: "/other-stories.html",
        name: "timeline",
      },
      {
        path: "/other-stories/1.html",
        name: "timeline",
      },
    ];

    Barba.use(barbaPrefetch);
    Barba.use(routes);

    const options = this.getBarbaOptions();
    Barba.init(options);
  }

  handleScroll() {
    this.removeFirstScrollHandlers();
    this.setIsFirstScreenPlayed();

    const tl = document.querySelector('[data-barba-namespace="timeline"]');
    const allHistory = document.querySelector(
      '[data-barba-namespace="history"]'
    );

    const obj = {
      value: 0,
    };

    gsap
      .timeline({
        onStart: () => {
          this.music.changeVolume(0.3);
        },
        onComplete: () => {
          window.analytics.trackPage("selection", "/slide0-selection");
        },
      })
      .to(
        obj,
        0.5,
        {
          value: 0.2,
          onUpdate: () => {
            if (!tl || !allHistory)
              this.line.geometry.maxInstancedCount = 100 * obj.value;
          },
        },
        "+=1"
      );
  }

  removeFirstScrollHandlers() {
    if (this.callToScroll) {
      this.callToScroll.removeEventListener("click", this.handleScroll);
    }

    window.removeEventListener(this.wheelEvent, this.handleScroll);
    window.removeEventListener("touchmove", this.handleScroll);
  }

  addFirstScrollHandlers() {
    this.wheelEvent = this.getWheelEvent();

    this.callToScroll.addEventListener("click", this.handleScroll, {
      once: true,
    });

    window.addEventListener(this.wheelEvent, this.handleScroll, { once: true });
    window.addEventListener("touchmove", this.handleScroll, { once: true });
  }

  start() {
    gsap
      .timeline({
        delay: 2,
      })

      // loader animation
      .fromTo(
        this.classes.loader.sound,
        0.6,
        { autoAlpha: 0, scale: 1.05 },
        { autoAlpha: 1, scale: 1 }
      )
      .staggerFromTo(
        this.classes.loader.texts,
        1.2,
        {
          scale: 1.1,
          y: "30%",
          autoAlpha: 0,
        },
        {
          scale: 1,
          y: "-50%",
          autoAlpha: 1,
          ease: "power2.easeIn",
          onStart: () => this.trackIntro(),
        },
        4.8,
        "+=2"
      )
      .staggerTo(
        this.classes.loader.texts,
        0.8,
        {
          scale: 0.9,
          y: "-130%",
          autoAlpha: 0,
          ease: "power2.easeIn",
        },
        4.8,
        "-=1.2"
      )

      // home page animation
      .fromTo(
        [this.classes.intro.inner, this.classes.intro.logo],
        1,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          onStart: () => {
            gsap.set(this.classes.intro.logo, {
              className: `+=${
                document.querySelector(this.classes.intro.logo).classList
              } is-animating`,
            });
            this.music.setStory("intro");
            this.music.playTrack("logo");
            window.analytics.trackPage("logo", tags.pages.logo);
          },
        },
        "+=1"
      )
      .fromTo(
        this.classes.home.title,
        1,
        { autoAlpha: 0, x: "-10%" },
        { autoAlpha: 1, x: isEn() ? 0 : "-28%" }
      )
      .fromTo(
        this.classes.home.call,
        1,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          onStart: () => {
            document
              .querySelector(this.classes.home.call)
              .classList.add("is-active");

            this.addFirstScrollHandlers();
            this.initBarba();
          },
        }
      );
  }

  reinitLine() {
    const { maxInstancedCount } = this.line.geometry;
    const isStory = Boolean(document.querySelector("[data-story]"));

    this.canvas.remove(this.line);

    this.line = this.createLine();
    this.line.name = "reinited";
    this.line.geometry.maxInstancedCount = maxInstancedCount;
    this.line.position.x = isStory ? -this.size.width : 0;

    if (Barba && Barba.transitions && Barba.transitions.store) {
      Barba.transitions.store.all[0].line = this.line;
      Barba.transitions.store.all[1].line = this.line;
    }
  }

  onResize() {
    window.clearTimeout(this.timeout);

    this.updateViewportUnits();
    this.recalcConsts();

    this.modules.forEach((item) => (item.resize ? item.resize() : false));
    this.timeout = window.setTimeout(() => this.reinitLine(), 500);

    this.accordions.forEach((item) => item.resize());
  }

  initHandlers() {
    window.addEventListener("resize", _debounce(this.onResize.bind(this), 300));
  }

  handleModal() {
    if (this.modalTl && this.modalTl.isActive()) return;

    const { width } = this.size;

    const { visible } = this.classes.modal.states;

    document.body.classList.toggle(visible);

    const isVisible = document.body.classList.value.indexOf(visible) !== -1;
    const delay = isVisible ? 0 : "+=0.1";

    const tl = gsap
      .timeline({
        pause: true,
      })
      .fromTo(this.classes.tabs, 1, { x: width }, { x: 0, ease: "power4.out" })
      .fromTo(
        this.classes.content,
        1,
        { autoAlpha: 1 },
        { autoAlpha: 0, ease: "power4.out" },
        "-=1"
      );
    const hashSlide = this.museum.getSlide("just-say");
    const hashLines = hashSlide ? hashSlide.getLines() : null;

    if (hashLines && hashLines.length) {
      const obj = { value: 1 };

      tl.add(
        gsap.to(obj, 1, {
          value: 0,
          ease: "power4.out",
          onUpdate: () => {
            hashLines.forEach((item) => {
              const line = item;
              if (line) line.geometry.maxInstancedCount = obj.value * 100;
            });
          },
        }),
        "-=1"
      );
    }

    this.modalTl = gsap
      .timeline({
        onStart: () =>
          isVisible ? this.music.play("main") : this.music.play("final"),
      })
      .add(isVisible ? tl.play() : tl.reverse())
      .set(this.classes.tabs, { autoAlpha: () => (isVisible ? 1 : 0) }, delay);
  }

  changeVolumeState(volume) {
    const media = [...document.querySelectorAll("audio, video")];

    media.forEach((item) => {
      if (item.classList.value.indexOf("js-muted") !== -1) return;
      if (item.closest(".video-js")) return;
      if (item.closest(".js-audio")) return;

      const el = item;
      el.volume = volume;
    });
  }

  handleGlobalVolumeUpdate(volume) {
    this.changeVolumeState(volume);
    this.museum.changeVideoVolume(volume);
  }

  unmute() {
    this.waves.play();
    this.music.unmute(this.handleGlobalVolumeUpdate);
  }

  mute() {
    this.waves.pause();
    this.music.mute(this.handleGlobalVolumeUpdate);
  }

  changeMusicMuteState(isResettingValues) {
    if (!isResettingValues) {
      const action = this.muted
        ? tags.actions.audio.on
        : tags.actions.audio.off;

      window.analytics.trackAction(action, {
        event_category: tags.actions.audio.category,
      });
    }

    if (this.muted) {
      this.unmute();
    } else {
      this.mute();
    }
  }

  handleVoiceBtnClick({ isResettingValues }) {
    this.muted = this.waves.getWavesState();

    if (!this.isInteracted && !this.muted && !isResettingValues) return;
    if (!this.muted && !this.music.isAudioUnlocked()) {
      this.music.setVolume(0);
      this.music.changeVolume(1);
      return;
    }

    this.changeMusicMuteState(isResettingValues);
  }

  initVoiceButton() {
    this.handleVoiceBtnClick({ isResettingValues: true });

    [...document.querySelectorAll(this.classes.voice)].forEach((item) => {
      item.addEventListener("click", this.handleVoiceBtnClick.bind(this));
    });
  }

  initModal() {
    const btn = [...document.querySelectorAll(this.classes.modal.btn)];

    btn.forEach((item) => item.removeEventListener("click", this.handleModal));
    btn.forEach((item) => item.addEventListener("click", this.handleModal));
  }

  initAccordions() {
    const accordions = document.querySelectorAll(
      this.classes.accordion.wrapper
    );

    this.accordions = [...accordions].map(
      (container) =>
        new Accordion({
          container,
          classes: {
            wrapper: this.classes.accordion.wrapper,
            item: this.classes.accordion.item,
          },
        })
    );

    this.accordions.forEach((item) => item.init());
  }

  initTabs() {
    const tabs = document.querySelectorAll(this.classes.tabs);
    this.tabs = [...tabs].map((container) => new Tabs({ container }));
  }

  initModules() {
    this.modules.forEach((module) => module.init());

    this.initTabs();
    this.initAccordions();
    this.initModal();
    this.initVoiceButton();
    this.initTagTriggers();
  }

  createModules() {
    this.music = new Music({ audio: AUDIO });

    this.museum = new Museum({
      canvas: this.canvas,
      music: this.music,
      states: {
        muted: this.muted,
      },
    });

    this.waves = new Waves({
      container: this.classes.mute,
    });

    this.modules = [this.museum, this.waves];
  }

  recalcConsts() {
    this.size = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  setValues() {
    this.recalcConsts();
    this.updateViewportUnits();

    this.container = document.querySelector(this.classes.container);
    this.callToScroll = document.querySelector(this.classes.home.call);
    this.intro = document.querySelector(this.classes.intro.container);
  }

  createLine() {
    const { width, height } = this.size;

    return this.canvas.createNewLine(
      [
        {
          top: height * 0.5,
          left: 0,
        },
        {
          top: height * 0.5,
          left: width,
        },
      ],
      100,
      false
    );
  }

  initCanvas() {
    this.canvas = new Canvas({ container: ".js-canvas" });
    this.canvas.init();

    this.line = this.createLine();
    this.line.name = "inited";
    this.line.geometry.maxInstancedCount = 0;
  }

  preload(callback = () => {}) {
    const { length } = this.data;

    this.body.dataset.total = length;

    let counter = 0;

    this.data.forEach((src) => {
      const req = new XMLHttpRequest();
      req.open("GET", src, true);

      req.onload = () => {
        counter += 1;
        this.body.dataset.current = counter;

        if (counter === length) callback();
      };
      req.send();
    });
  }

  fetchData() {
    fetch(`${API_URL}/stories`)
      .then((response) => response.json())
      .then((data) => {
        this.stories = data;
        this.generateOtherStories();
        this.loadSelectedStory();
      });
  }

  generateOtherStories() {
    const storyItem = document.querySelector(".story-items");

    if (!storyItem) {
      return;
    }

    const storyItemChild = storyItem.children[0];

    if (!storyItemChild) {
      return;
    }

    this.stories.forEach((item) => {
      const itemEl = storyItemChild.cloneNode(true);
      itemEl.querySelector(".headline").innerHTML = item.title;
      itemEl.querySelector(".description").innerHTML = item.text;
      itemEl
        .querySelector(".image")
        .setAttribute(
          "src",
          `https://img.youtube.com/vi/${item.youtube_id}/maxresdefault.jpg`
        );
      itemEl.setAttribute("href", `/other-stories-details.html?id=${item.id}`);
      storyItem.appendChild(itemEl);
    });

    storyItem.removeChild(storyItemChild);
  }

  handleHistoryChanged(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const nextStoryId = event.currentTarget.dataset.id;
    if (!nextStoryId) {
      return;
    }

    const url = new URL(window.location.href);
    const params = url.searchParams;

    if (params.has('id')) {
      params.set("id", nextStoryId);
    } else {
      params.append("id", nextStoryId)
    }

    history.pushState({}, null, url.href)

    this.loadSelectedStory(false);
}

  loadSelectedStory(useHandlers = true) {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");

    if (!id) {
      return;
    }

    if (this.currentStoryId === id) {
      return;
    }

    this.currentStoryId = id;

    const selectedStoryIndex = this.stories.findIndex((item) => {
      return item.id === parseInt(id);
    });

    const selectedStory = this.stories[selectedStoryIndex];
    const nextStory = this.stories[selectedStoryIndex + 1];
    const prevStory = this.stories[selectedStoryIndex - 1];

    const storyItem = document.querySelector(".story-details");

    storyItem.querySelector(".headline").innerHTML = selectedStory.title;
    storyItem.querySelector(".description").innerHTML = selectedStory.text;
    storyItem.querySelector(".current-index").innerHTML = selectedStoryIndex + 1;
    storyItem.querySelector(".total-index").innerHTML = this.stories.length;

    storyItem
      .querySelector(".youtube")
      .setAttribute(
        "src",
        `https://www.youtube.com/embed/${selectedStory.youtube_id}?fs=0`
      );

    if (selectedStory && nextStory) {
      const nextLink = storyItem.querySelector(".next-link");
      nextLink.setAttribute("href", `/other-stories-details.html?id=${nextStory.id}`);
      nextLink.setAttribute("data-id", nextStory.id);

      storyItem.querySelector(".next-link").style.display = "block";
    } else {
      storyItem.querySelector(".next-link").style.display = "none";
    }

    if (selectedStory && prevStory) {
      const prevLink = storyItem.querySelector(".prev-link");
      prevLink.setAttribute("href", `/other-stories-details.html?id=${prevStory.id}`);
      prevLink.setAttribute("data-id", prevStory.id);

      storyItem.querySelector(".prev-link").style.display = "block";
    } else {
      storyItem.querySelector(".prev-link").style.display = "none";
    }

    if (useHandlers) {
      [...document.querySelectorAll(this.classes.otherStories.link)].map(item => {
        item.removeEventListener('click', this.handleHistoryChanged);
        item.addEventListener('click', this.handleHistoryChanged);
      });
    }
  }

  init() {
    this.setValues();
    this.initCanvas();
    this.createModules();
    this.initModules();
    this.initHandlers();

    handleUserInteraction(() => {
      this.isInteracted = true;
    });

    if (document.querySelector('[data-barba-namespace="intro"]')) {
      this.start();
      this.music.play("intro");
    } else {
      this.initBarba();
    }

    document.body.click();
  }
}

const init = () => {
  const app = new App();
  app.updateViewportUnits();

  const preloader = new Preloader();
  preloader.init();

  app.preload(() => {
    preloader.done(() => {
      app.init();
    });
  });
};

init();

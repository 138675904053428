import gsap from 'gsap';

import CONSTS from '../../consts';

const { tags } = CONSTS;

const CLASSES = {
  progress: '.js-progress',
  arrows: '.js-timeline-arrows',
  tag: '.js-just-say-tag'
};

const updateLineValue = (item, value, maxInstance = 100) => {
  if (!item) return;

  const line = item;
  line.geometry.maxInstancedCount = value * maxInstance;
};

const getHideControlsAnimation = (callback) => gsap
  .timeline({
    paused: true
  })
  .to([CLASSES.arrows, CLASSES.progress], 1, { autoAlpha: 0, onStart: callback })
;

const tagLineAnimation = (slide, container, id) => {
  const progress = { value: 0 };

  const tag = container.querySelector(CLASSES.tag);

  updateLineValue(slide.getLines()[0], progress.value);

  const {
    innerWidth
  } = window;

  let width = tag.offsetWidth;

  return gsap
    .timeline({
      paused: true,
    })
    .add(getHideControlsAnimation(() => {
      progress.value = 0;
      width = tag.offsetWidth;
      updateLineValue(slide.getLines()[0], progress.value);
    }).play(), 0)
    .fromTo(
      progress,
      1,
      { value: 0 },
      {
        value: 1,
        ease: 'power4.out',
        onUpdate: () => updateLineValue(slide.getLines()[0], progress.value),
        onStart: () => {
          if (id) window.analytics.trackPage('share-justsay', `${tags.pages.justsay}`);

          const line = slide.getLines()[0];

          line.scale.x = ((innerWidth + width) * 0.5) / width;
          line.position.x = - (innerWidth - width * line.scale.x) * 0.5;
        }
      }
    )
    .to(
      progress,
      1,
      {
        value: 0,
        onUpdate: () => {
          const line = slide.getLines()[0];
          const scale = ((innerWidth + width) * 0.5) / width;
          const x = -(innerWidth - width * scale) * 0.5;

          line.scale.x = (scale - 1) * progress.value + 1;
          line.position.x = x * progress.value;
        }
      }
    )
  ;
};

export default {
  name: 'just-say',
  reverse: {
    forwards: ({ slide, container }) => tagLineAnimation(slide, container)
  },
  timeline: ({ slide, container, id }) => tagLineAnimation(slide, container, id)
};

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getDotPosition", "getAnimation", "initDefault", "resetLines", "isWaved", "resize"] }] */
import gsap from 'gsap';

import Video360 from './video360';

import Animations from '../animations';

import CONSTS from '../consts';

const { tags } = CONSTS;

function generateDots (el, {
  innerWidth,
  innerHeight
}) {
  const elements = [...el];

  const middleY = innerHeight * 0.5;

  const dots = [];
  dots.push(
    {
      top: middleY,
      left: 0
    },
    {
      top: middleY,
      left: (elements[0].getBoundingClientRect().left - this.data.start.x) * 0.8
    }
  );

  const waves = elements.map((item, index) => {
    const { top, left } = item.getBoundingClientRect();

    return {
      top: index ? top : middleY,
      left: left - this.data.start.x
    }
  });

  dots.push(...waves);

  dots.push(
    {
      top: middleY,
      left: innerWidth
    }
  );

  return dots;
}

export default class Slide {
  constructor({
    index,
    container,
    canvas,
    data,
    id,
    methods,
    music,
    classes,
    scroll
  }) {
    this.classes = {
      dots: {
        item: '.js-dot',
        container: '.js-dots-container',
        brokenTimelineContainer: '.js-break-dots-container',
        element: '.js-dot-element'
      },
      hover: {
        item: '.js-hover-item',
        container: '.js-hover-container'
      },
      state: {
        hovered: 'is-hovered'
      },
      trigger: {
        animation: '.js-trigger-animation'
      },
      video: {
        btn: '.js-video-btn'
      },
      arrows: classes ? classes.arrows : null
    };

    this.methods = methods;

    this.container = container;
    this.canvas = canvas;
    this.music = music;
    this.scroll = scroll;

    this.id = id;
    this.index = index;

    this.story = data;

    this.arrows = [...document.querySelectorAll(this.classes.arrows)];

    this.type = this.container.dataset.type;

    this.data = {};

    this.type = this.container.dataset.type;
    this.name = this.container.dataset.slideName;

    this.initDefault = this.initDefault.bind(this);
    this.initGallery = this.initGallery.bind(this);
    this.initVideo = this.initVideo.bind(this);

    this.handleScrollToAnimate = this.handleScrollToAnimate.bind(this);
    this.scrollEvent = this.scrollEvent.bind(this);

    this.scrollToNextSlide = this.scrollToNextSlide.bind(this);

    this.wheelEvent = CONSTS.wheelEvent;
  }

  isVideoPlaying() {
    return this.video.isPlaying();
  }

  unmute() {
    if (!this.video) return;
    this.video.unmute();
  }

  mute() {
    if (!this.video) return;
    this.video.mute();
  }

  getTimelineLine() {
    return this.timeline;
  }

  getLines() {
    return this.lines && this.lines.length ? this.lines : [];
  }

  getDotPosition(type, { x, y, height, width }) {
    const top = (() => {
      switch(type) {
        case 'top':
          return y;
        case 'half-center':
          return y + (height * 0.25);
        default:
          return y + (height * 0.5);
      }
    })(type, y);
    // const top = type === 'center' ? y + (height * 0.5): y;
    const left = x + width * 0.5;

    return {
      top,
      left
    };
  }

  resetLines() {
    if (this.lines && this.lines.length) this.lines.forEach(item => {
      const line = item;
      line.geometry.maxInstancedCount = 0;
    });
  }

  generateBrokenTimeline() {
    const brokenTimelineContainer = this.container.querySelector(this.classes.dots.brokenTimelineContainer);
    if (!brokenTimelineContainer) return;

    const dots = [...brokenTimelineContainer.querySelectorAll(this.classes.dots.element)];
    if (dots.length) {
      const brokenLine = this.canvas.createBrokenTimeline(dots);
      brokenLine.forEach(item => this.lines.push(item));
    }
  }

  generateTimeline() {
    if (!this.dots.length) return;

    const {
      innerHeight,
      innerWidth
    } = window;

    if (this.name === 'gallery') {
      this.dots.shift();
      this.dots.shift();

      const dots = [
        {
          top: innerHeight * 0.5,
          left: innerWidth * 0.28
        },
        {
          top: innerHeight * 0.5,
          left: innerWidth * 0.1
        },
        {
          top: innerHeight * 0.5,
          left: 0
        }
      ];
      
      dots.forEach(dot => this.dots.unshift(dot));
    }

    this.canvas.createTimeline(this.dots);
    this.timeline = this.canvas.getTimeline();

    this.timeline.material.opacity = 0;
    this.timeline.geometry.maxInstancedCount = 0;
  }

  isWaved(container) {
    return container.dataset && container.dataset.waved;
  }

  generateLines() {
    this.lines = [];

    const containers = [...this.container.querySelectorAll(this.classes.dots.container)];
    if (!containers.length) return;

    const linesContainer = this.container.querySelector('[data-lines-custom]');
    const isCustomLines = Boolean(linesContainer);

    const {
      innerHeight,
      innerWidth
    } = window;

    if (isCustomLines) {
      this.lines = containers.map((container) => {
        const dots = [...container.querySelectorAll(this.classes.dots.element)];
        if (!dots.length) return undefined;

        const isWaved = this.isWaved(container);

        if (isWaved) {
          const points = dots.map(point => ({
            top: point.getBoundingClientRect().top,
            left: point.getBoundingClientRect().left
          }));

          return this.canvas.createNewLine(points, 200, false);
        };
        

        const points = dots.map((point) => {
          const calcFromParent = point.dataset && point.dataset.parentOffset;

          const left = calcFromParent ? point.offsetLeft + point.parentElement.offsetLeft + container.offsetLeft : point.offsetLeft;
          const top = calcFromParent ? container.offsetTop : point.offsetTop;

          return {
            top,
            left
          };
        });

        return this.canvas.createNewLine(points, 100, false);
      }).filter(item => item);
    } else {
      const linePoints = [
        {
          top: innerHeight * 0.5,
          left: 0
        },
        {
          top: innerHeight * 0.5,
          left: innerWidth * 0.2
        },
        {
          top: innerHeight * 0.49,
          left: innerWidth * 0.3
        },
        {
          top: innerHeight * 0.42,
          left: innerWidth * 0.4
        },
        {
          top: innerHeight * 0.58,
          left: innerWidth * 0.38
        },
        {
          top: innerHeight * 0.41,
          left: innerWidth * 0.52
        },
        {
          top: innerHeight * 0.59,
          left: innerWidth * 0.47
        },
        {
          top: innerHeight * 0.44,
          left: innerWidth * 0.62
        },
        {
          top: innerHeight * 0.58,
          left: innerWidth * 0.57
        },
        {
          top: innerHeight * 0.47,
          left: innerWidth * 0.77
        },
        {
          top: innerHeight * 0.5,
          left: innerWidth
        }
      ];

      const lastPoints = [
        {
          top: innerHeight * 0.5,
          left: 0
        },
        {
          top: innerHeight * 0.5,
          left: innerWidth
        }
      ];

      const getDots = generateDots.bind(this);

      this.lines = (
        containers
          .map((item, index, arr) => {
            const isWaved = this.isWaved(item);
            const lineDots = isWaved
              ? getDots(
                  item.querySelectorAll(this.classes.dots.element),
                  {
                    innerWidth,
                    innerHeight
                  }
                )
              : linePoints
            ;
            const points = (index === arr.length - 1) ? lastPoints : lineDots;
            return this.canvas.createNewLine(points, 200, false);
          })
        )
      };
    ;

    this.lines.forEach((item, index) => {
      const line = item;
      line.name = `${this.name}-${index}`;
    });
  }

  generateDots() {
    const dots = this.container.querySelectorAll(this.classes.dots.item);

    this.dots = [...dots].map(item => {
      const {
        height,
        x,
        y,
        width
      } = item.getBoundingClientRect();

      const { inner } = this.data.container;
      const offsetX = inner.style.transform ? Math.abs(Number(inner.style.transform.split('(')[1].split('px')[0])) : 0;

      const type = item.dataset && item.dataset.pos ? item.dataset.pos : 'center';
      const {
        top,
        left
      } = this.getDotPosition(type, { x: x + offsetX, y, height, width });

      return {
        top,
        left
      };
    });

    this.dots.unshift({
      top: window.innerHeight * 0.5,
      left: 0
    });
  }

  getMultipleAnimationsStatus() {
    return this.isMultipleTimesAnimated ? this.isMultipleAnimationsCompleted : true;
  }

  scrollEvent(index, callback) {
    function handleEvent(e) {
      if (this.isLast) {
        this.isMultipleAnimationsCompleted = true;
        return;
      }

      const { type } = e;

      this.triggerElements.forEach(item => item.removeEventListener('click', this.handleEvent));
      this.arrows.forEach(item => item.removeEventListener('click', this.handleEvent));
      this.container.removeEventListener(this.wheelEvent, this.handleEvent);

      this.methods.hideArrows();
      if ((type.indexOf('wheel') !== -1) || (type.indexOf('Scroll') !== -1)) {
        this.methods.trackMoveEvent(this.isCustom ? tags.actions.arrow.action : tags.actions.scroll.action);
      }

      this.tl[index]
        .eventCallback('onComplete', callback)
        .play(0)
      ;
    }

    if (this.isLast) return;

    this.handleEvent = handleEvent.bind(this);

    this.container.addEventListener(this.wheelEvent, this.handleEvent, { once: true });
    this.arrows.forEach(item => item.addEventListener('click', this.handleEvent, { once: true }));
    this.triggerElements.forEach(item => item.addEventListener('click', this.handleEvent, { once: true }));
  }

  handleScrollToAnimate(callback) {
    this.triggerElements = [...this.container.querySelectorAll(this.classes.trigger.animation)];

    function recursion(max, index) {
      this.isLast = index === max;
      if (this.isLast) callback();

      this.scrollEvent(index, () => {
        this.methods.showArrows();
        this.recursion(max, index + 1);
      });
    };

    this.recursion = recursion.bind(this);

    this.tl[0]
      .eventCallback('onComplete', () => {
        this.max = this.tl.length;
        this.methods.showArrows();
        this.recursion(this.max, 1)
      })
      .play(0)
    ;
  }

  getBeforeStartTimeline() {
    return this.beforeStartTl ? this.beforeStartTl : null;
  }

  getBeforeEndTimeline() {
    return this.beforeEndTl ? this.beforeEndTl : null;
  }

  getForwardsTimeline() {
    return this.forwardsTl ? this.forwardsTl : null;
  }

  getReverseTimeline() {
    return this.reverseTl ? this.reverseTl : null;
  }

  getTimeline(callback) {
    const {
      tl,
      handleScrollToAnimate
    } = this;

    if (tl instanceof Array) {
      this.isMultipleTimesAnimated = true;

      return {
        play() {
          handleScrollToAnimate(callback);
        },
        progress() {
          return tl[0].progress();
        },
        animations: this.tl
      };
    }

    if (tl) tl.eventCallback('onComplete', callback);
    return tl;
  }

  pause() {
    if (this.video) this.video.pause();
  }

  start() {
    if (this.video) this.video.play();
  }

  initHovers() {
    const containers = this.container.querySelectorAll(this.classes.hover.item);
    [...containers].forEach((item) => {
      const id = item && item.dataset ? item.dataset.item : null;
      if (!id) return;

      const links = document.querySelectorAll(`[data-item="${id}"]`);
      const container = document.querySelector(this.classes.hover.container);

      [...links].forEach((link) => {
        link.addEventListener('mouseenter', () => container.classList.add(this.classes.state.hovered));
        link.addEventListener('mouseleave', () => container.classList.remove(this.classes.state.hovered));
      })
    });
  }

  initDefault() {
    this.initHovers();
  }

  removeLines() {
    const materials = this.lines.map(item => item.material);
    gsap.to(
      materials,
      1,
      {
        opacity: 0,
        onComplete: () => {
          this.lines.forEach(item => this.canvas.remove(item));
          this.lines = [];
        }
      }
    );
  }

  removeCanvasObjects() {
    this.canvas.removeTimeline();
    this.canvas.removeImages();
    this.canvas.removeParallax();
    this.canvas.removeBrokenImage();

    this.removeLines();
  }

  initCanvasObjects() {
    const { inner } = this.data.container;

    this.canvas.createImages(inner);
    this.canvas.createParallax();
    this.canvas.createBrokenImage(this.story.brokenImage, this.id);

    this.generateTimeline();
  }

  initGallery() {
    this.initCanvasObjects();
    setTimeout(() => this.resize(), 1000);
  }

  handleVideoBtnClick() {
    this.pause();
    this.scrollToNextSlide();
  }

  initVideoHandlers() {
    this.videoBtn.addEventListener('click', this.handleVideoBtnClick.bind(this));
    this.video.handleVideoFinish = () => this.scrollToNextSlide();
  }

  initVideoValues() {
    this.videoBtn = this.container.querySelector(this.classes.video.btn);
  }

  initVideo() {
    this.video = new Video360({
      id: this.id
    });
    this.video.init();

    this.initVideoValues();
    this.initVideoHandlers();
  }

  getReverseAnimations() {
    const animation = Animations.filter(item => (item.name === this.name) ? item : null)[0];
    return animation ? animation.reverse : null;
  }

  getBeforeEndAnimation() {
    const animation = Animations.filter(item => (item.name === this.name) ? item : null)[0];
    return animation ? animation.beforeEnd : null; 
  }

  getAnimation() {
    const animation = Animations.filter((item) => {
      const { name, timeline } = item;
      if (this.name === name) return timeline;
      return false;
    });

    return animation.length && animation[0] ? animation[0].timeline : null;
  }

  scrollToNextSlide() {
    setTimeout(() => this.methods.next(), 500);
  }

  generateAnimationOptions() {
    this.options = {
      slide: this,
      id: this.id,
      container: this.container,
      canvas: this.canvas,
      music: this.music,
      lines: this.getLines(),
      timeline: this.getTimelineLine(),
      video: this.video,
      methods: this.methods
    };
  }

  generateAnimations() {
    this.generateAnimationOptions();

    const { options } = this;

    const animation = this.getAnimation();
    this.tl = animation instanceof Function
      ? animation(options, () => this.scrollToNextSlide())
      : null
    ;

    const beforeEndAnimation = this.getBeforeEndAnimation();
    this.beforeEndTl = beforeEndAnimation instanceof Function ? beforeEndAnimation(options) : null;

    const reverseAnimation = this.getReverseAnimations();

    this.reverseTl = reverseAnimation && reverseAnimation.reverse ? reverseAnimation.reverse(options) : null;
    this.forwardsTl = reverseAnimation && reverseAnimation.forwards ? reverseAnimation.forwards(options) : null;
  }

  setValues() {
    const { container } = this;

    const {
      innerWidth,
      innerHeight
    } = window;

    const {
      offsetLeft,
      offsetTop,
      scrollWidth,
      scrollHeight
    } = container;

    this.data = {
      container: {
        outer: container,
        inner: this.type === 'gallery' ? container.children[0] : null
      },
      start: {
        x: offsetLeft,
        y: offsetTop
      },
      end: {
        x: offsetLeft + scrollWidth - innerWidth,
        y: offsetTop + scrollHeight - innerHeight
      },
      duration: {
        x: scrollWidth,
        y: scrollHeight
      }
    };

    this.isScrolled = scrollWidth > innerWidth;
  }

  getSlideInitMethod() {
    return ({
      'video': this.initVideo,
      'gallery': this.initGallery,
      'default': this.initDefault
    }[this.type]);
  }

  resetTimeline() {
    if (!this.timeline) return;

    const { x } = this.timeline.position;

    this.generateTimeline();

    this.timeline.position.x = x;
    this.timeline.geometry.maxInstancedCount = 100;
  }

  getAllAnimations() {
    return [
      this.tl,
      this.beforeEndTl,
      this.reverseTl,
      this.forwardsTl
    ];
  }

  resizeLines() {
    const data = this.lines.map(item => ({
      maxInstancedCount: item.geometry.maxInstancedCount,
      position: item.position,
      visible: item.visible
    }));

    this.lines.forEach(item => this.canvas.remove(item));

    this.generateCanvasLines();
    this.lines.forEach((item, index) => {
      const currentData = data[index];
      const line = item;

      line.geometry.maxInstancedCount = currentData.maxInstancedCount;
      line.visible = currentData.visible;
    });
  }

  resizeTimeline() {
    if (!this.timeline) return;

    this.generateDots();
    this.generateTimeline();

    this.timeline.geometry.maxInstancedCount = 100;
  }

  resizeVideo() {
    this.video.resize();
  }

  resizeGallery() {
    this.resizeTimeline();
  }

  resize() {
    this.setValues();
    this.resizeLines();

    switch(this.name) {
      case 'gallery':
        this.resizeGallery();
        break;
      case 'video':
        this.resizeVideo();
        break;
      default:
    };
  }

  generateCanvasLines() {
    this.generateDots();
    this.generateLines();
    this.generateBrokenTimeline();
  }

  destroy() {
    this.removeCanvasObjects();
    if (this.video) this.video.destroy();
  }

  init() {
    if (!this.container) return;

    this.setValues();
    this.generateCanvasLines();

    const initSlide = this.getSlideInitMethod();
    if (initSlide) initSlide();

    this.generateAnimations();
    this.resetLines();
  }
};

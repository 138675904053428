export default class Item {
  constructor(el, container, scene) {
    this.scene = scene;
    this.container = container;
    // the .item element
    // this.scroll = scroll;
    this.DOM = { el };
    this.currentScroll = 0;
    this.animated = false;
    this.isBeingAnimatedNow = false;
    this.shouldRollBack = false;
    this.shouldUnRoll = false;
    this.positions = [];

    this.winsize = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    this.getSize();

    this.mesh = scene.createMesh({
      width: this.width,
      height: this.height,
      src: this.src,
      image: this.DOM.el,
      iWidth: this.DOM.el.width,
      iHeight: this.DOM.el.height
    });

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: [0, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
    };

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.positions.push(entry.boundingClientRect.y);
        const compareArray = this.positions.slice(
          this.positions.length - 2,
          this.positions.length
        );
        const down = compareArray[0] > compareArray[1];

        this.isVisible = entry.intersectionRatio > 0.0;

        this.shouldRollBack = false;
        this.shouldUnRoll = false;
        if (
          entry.intersectionRatio < 0.5 &&
          entry.boundingClientRect.y > 0 &&
          this.isVisible &&
          !down
        ) {
          this.shouldRollBack = true;
        }

        if (
          entry.intersectionRatio > 0.5 &&
          entry.boundingClientRect.y > 0 &&
          this.isVisible
        ) {
          this.shouldUnRoll = true;
        }

        this.mesh.visible = this.isVisible;
      });
    }, options);
    this.observer.observe(this.DOM.el);
    // init/bind events
    this.render(0);
  }

  getSize() {
    // get all the sizes here, bounds and all
    const bounds = this.DOM.el.getBoundingClientRect();
    const fromTop = bounds.top;
    const windowHeight = window.innerHeight;
    const withoutHeight = fromTop - windowHeight;
    const withHeight = fromTop + bounds.height;

    const offsetX = this.container && this.container.style.transform ? Math.abs(Number(this.container.style.transform.split('(')[1].split('px')[0])) : 0;

    this.insideTop = withoutHeight - this.currentScroll;
    this.insideRealTop = fromTop + this.currentScroll;
    this.insideBottom = withHeight - this.currentScroll + 50;
    this.width = bounds.width;
    this.height = bounds.height;
    this.left = bounds.left + offsetX;
  }

  resize() {
    this.winsize = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    this.getSize();
    this.mesh.scale.set(this.width, this.height, 200);
    this.render();
  }

  render() {
    this.mesh.position.y = this.winsize.height / 2 - this.insideRealTop - this.height / 2;
    this.mesh.position.x = 0 - this.winsize.width / 2 + this.left + this.width / 2;
  }

  getMesh() {
    return this.mesh;
  }
}
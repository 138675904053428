export default class Accordion {
  constructor({
    container,
    classes
  }) {
    this.container = container;
    this.classes = classes;

    this.currentIndex = -1;

    this.handleAccordionClick = this.handleAccordionClick.bind(this);
  }

  resize() {
    this.containers.forEach((item, index) => {
      item.removeAttribute('style');

      const current = this.items[index];

      current.height = item.offsetHeight;
      if (current.opened) this.open(index);
    })
  }

  closeAll(activeIndex) {
    this.items.forEach((item, index) => {
      const currentItem = item;
      if (item.opened && index !== activeIndex) currentItem.item.style.height = `${currentItem.height}px`;
    })
  }

  open(index) {
    if (index === this.currentIndex) return;

    this.closeAll();

    const current = this.items[index];
    if (!current) {
      return;
    }

    const { scrollHeight } = current.item;

    current.item.style.height = `${scrollHeight}px`;
    current.opened = true;

    this.currentIndex = index;
  }

  handleAccordionClick({ target }) {
    const item = target.closest(this.classes.item);
    if (item) {
      const index = this.containers.indexOf(item);
      if (index !== -1) this.open(index);
    }
  }

  resetAccordion() {
    this.open(0);
  }

  initHandlers() {
    this.container.addEventListener('click', this.handleAccordionClick);
  }

  initValues() {
    this.containers = [...this.container.querySelectorAll(this.classes.item)];
    this.items = this.containers.map((item, index) => ({
      item,
      opened: !index,
      height: item.offsetHeight
    }));
  }

  init() {
    this.initValues();
    this.initHandlers();
    this.resetAccordion();
  }
};

import gsap from 'gsap';

import CONSTS from '../../consts';

const { tags } = CONSTS;

const CLASSES = {
  container: '.js-isolation',
  inner: '.js-isolation-in',
  photo: '.js-isolation-photo',
  image: '.js-isolation-image',
  fear: {
    block: '.js-isolation-block',
    item: '.js-isolation-item',
    last: '.js-isolation-last-block'
  },
  box: {
    before: '.js-isolation-box-before',
    after: '.js-isolation-box-after'
  },
  isolation: {
    container: '.js-hysterics',
    path: '.js-hysterics-path',
    text: '.js-hysterics-text',
    mom: '.js-hysterics-mom',
    svg: '.js-hysterics svg'
  }
};

const progress = {
  x: 0
};

const brokenImageProgress = {
  x: 1
};

// @TODO: doc on load to get all fears items
const fearsAnimations = () => [...document.querySelectorAll(CLASSES.fear.block)]
  .map((item, index) => {
    const items = item.querySelectorAll(CLASSES.fear.item);
    const lastIndex = items.length - 1;
    const duration = lastIndex * 0.1 + 0.5;

    const tl =  gsap
      .timeline({
        paused: true
      })
      .fromTo([...items], 0.5, { scale: 1.2, autoAlpha: 0 }, { scale: 1, autoAlpha: 1, stagger: 0.1 })
      .to(CLASSES.photo, duration, { scale: `-=${index ? 0.15 : 0}`}, '-=0.6')
    ;

    if (index) tl.add(gsap.to(CLASSES.photo, 1, { autoAlpha: 1 }, `-=${lastIndex === index ? 0.5 : 0}`, 0));
    return tl;
  })
;

export default {
  name: 'isolation',
  reverse: {
    reverse: ({ music }) => gsap
      .timeline({
        paused: true
      })
      .set(
        [CLASSES.isolation.path, CLASSES.photo],
        {
          autoAlpha: 0,
          onStart: () => {
            music.play('hysterics');
          }
        }
      )
      .set([CLASSES.isolation.container, CLASSES.container, CLASSES.inner], { autoAlpha: 1 })
  },
  timeline: ({ slide, id, canvas, music, container }, callback = () => {}) => {
    const video = document.querySelector(`${CLASSES.container} video`);

    const el = {
      mom: container.querySelector(CLASSES.isolation.mom),
      text: container.querySelector(CLASSES.isolation.text),
      path: container.querySelector(CLASSES.isolation.path),
      svg: container.querySelector(CLASSES.isolation.svg)
    };

    return [
      gsap
        .timeline({
          paused: true
        })
        .to(
          brokenImageProgress,
          1.2,
          {
            x: 2,
            onStart: () => {
              video.play();
              music.play('hysterics');
              music.playTrack('isolation');
              window.analytics.trackPage('hysterics', `${tags.pages.story[id].subpage}${tags.pages.hysterics}`);
            },
            onUpdate: () => {
              canvas.setBrokenImageProgress(brokenImageProgress.x)
            }
          },
          0
        )
        .to(
          el.path,
          0.5,
          {
            strokeDashoffset: 1200,
            ease: 'power4.out',
            onStart: () => {
              setTimeout(() => music.playTrack('hystericsLine'), 100);
            }
          },
          '-=0.2'
        )
        .fromTo(
          el.mom,
          0.5,
          { x: () => window.innerWidth },
          { x: () => window.innerWidth * 0.51 },
          '-=0.4'
        )
        .to(el.path, 2, { strokeDashoffset: 0, ease: 'power4.out' }, '-=0.1')
        .to(el.svg, 1, { x: () => -window.innerWidth })
        .to(el.mom, 1, { x: 0 }, '-=1')
        .fromTo(
          el.text,
          1,
          { autoAlpha: 0 },
          {
            autoAlpha: 1,
            onComplete: () => {
              document.querySelector(CLASSES.inner).classList.add('has-background');
            },
          },
          '+=1'
        )
    ,
      gsap
        .timeline({
          paused: true
        })
        .fromTo(
          CLASSES.isolation.container,
          0.8,
          { autoAlpha: 1 },
          {
            autoAlpha: 0,
            onStart: () => {
              if (id === 'first') music.play('rejection');
              window.analytics.trackPage('isolation', `${tags.pages.story[id].subpage}${tags.pages.isolation}`);
            }
          }
        )
        .fromTo(CLASSES.photo, 0.8, { autoAlpha: 0 }, { autoAlpha: 1 })
        .fromTo([CLASSES.box.after, CLASSES.box.before], 0.8, { autoAlpha: 0 }, { autoAlpha: 1 }, '-=0.8')
        .fromTo(
          CLASSES.box.before,
          0.8,
          {
            xPercent: -80,
            yPercent: -140
          },
          {
            xPercent: -70,
            yPercent: -140
          },
          '-=1.6'
        )
        .fromTo(
          CLASSES.box.after,
          0.8,
          {
            xPercent: -80,
            yPercent: -60
          },
          {
            xPercent: -70,
            yPercent: -60
          },
          '-=1.6'
        )
        .to(
          CLASSES.image,
          0.8,
          {
            clipPath: 'polygon(10% 10%, 90% 10%, 90% 90%, 10% 90%)',
            translateX: '-50%',
            translateY: '-50%',
            scale: 1,
          },
          '-=0.8'
        )
        .add(fearsAnimations()[0].play(), '-=1')
    ,
      gsap
        .timeline({
          paused: true
        })
        .add(fearsAnimations()[1].play())
    ,
      fearsAnimations()
        .reduce((res, item, index) => {
          if (index <= 1) return res;
          res.add(item.play(), 0);
          return res;
        }, gsap.timeline({ paused: true })
      )
    ,
      gsap
        .timeline({
          paused: true
        })
        .to(CLASSES.fear.block, 0.6, { autoAlpha: 0 })
        .fromTo(CLASSES.fear.last, 1, { autoAlpha: 0 }, { autoAlpha: 1, onStart: () => music.stopCurrentTrack() })
        .to(CLASSES.fear.last, 0.6, { autoAlpha: 0 }, '+=1')
        .to(CLASSES.image, 0.6, { scale: 0, translateX: '-50%', translateY: '-50%' }, '-=0.6')
        .to([CLASSES.box.after, CLASSES.box.before], 0.6, { xPercent: -70, yPercent: -100 }, '-=0.6')
        .to([CLASSES.box.after, CLASSES.box.before], 0.2, { clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' })
        .fromTo(
          progress,
          1,
          { x: 0 },
          {
            x: 1,
            onStart: () => {
              music.playTrack('hystericsEndLine');
            },
            onUpdate: () => {
              const line = slide.getLines()[0];
              line.geometry.maxInstancedCount = progress.x * 100;
            }
          },
          '-=0.2'
        )
        .to(CLASSES.inner, 1, { autoAlpha: 0, onComplete: callback }, '-=1')
    ];
  }
};

import isolation from './timelines/_isolation';
import breakpoint from './timelines/_breakpoint';
import video from './timelines/_video';
import trying from './timelines/_trying';
import final from './timelines/_final';
import gallery from './timelines/_gallery';
import history from './timelines/_history';
import son from './timelines/_son';
import justSay from './timelines/_just-say';
import hysterics from './timelines/_hysterics';

export default [
  isolation,
  breakpoint,
  video,
  trying,
  final,
  gallery,
  history,
  son,
  justSay,
  hysterics
];

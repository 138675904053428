const handleInteraction = (callback) => {
  document.body.removeEventListener('keydown', callback);
  document.body.removeEventListener('click', callback);
  document.body.removeEventListener('touchstart', callback);
};

const checkUserInteraction = (callback) => {
  document.body.addEventListener('keydown', callback, { once: true });
  document.body.addEventListener('click', callback, { once: true });
  document.body.addEventListener('touchstart', callback, { once: true });
};

export default function check(callback) {
  checkUserInteraction(() => {
    handleInteraction();
    callback();
  });
};

import gsap from 'gsap';

export default class Preloader {
  constructor({ data } = {}) {
    this.classes = {
      container: '.js-preloader',
      inner: '.js-preloader-inner',
      content: '.js-content',
      item: '.js-preloader-item'
    };

    this.data = data || [];

    this.value = this.step;

    this.maxValue = 100;
    
    this.delay = 2600;
  }

  done(callback = () => {}) {
    document.body.classList.add('is-content-loaded');
    this.value = this.maxValue;

    this.complete(callback);
  }

  complete(callback) {
    gsap
      .timeline({
        delay: 1.5
      })
      .to(
        this.classes.container,
        1.2,
        {
          autoAlpha: 0,
          onStart: callback,
          onComplete: () => {
            gsap.to(
              this.classes.content,
              1,
              {
                autoAlpha: 1,
                onComplete: () => {
                  this.container.style.display = 'none';
                }
              }
            )
          }
        },
        `+=1`
      )
    ;
  }

  initValues() {
    this.items = [...this.container.querySelectorAll(this.classes.item)];
  }

  init(callback = () => {}) {
    this.container = document.querySelector(this.classes.container);
    if (!this.container) return;

    // check is DEV
    const container = document.querySelector('[data-mod]');
    if (container && container.dataset && container.dataset.mod) {
      this.complete(callback);
      return;
    }

    this.initValues();
  }
};

import gsap from 'gsap';

import CONSTS from '../../consts';

const { tags } = CONSTS;

const CLASSES = {
  container: '.js-trying',
  block: '.js-trying-block',
  blockMod: '.js-trying-block-mod',
  headline: '.js-trying-headline',
  lastHeadline: '.js-trying-last-headline',
  text: '.js-trying-text'
};

const START_AT = 0.3;

const progress = {
  x: START_AT
};

const imageProgress = {
  value: 0
};

const lerp = (a,b,t) => a * (1 - t) + b * t;

// @TODO: to utils
const updateLineValue = (item, value, coef = 1) => {
  if (!item) return;

  const line = item;
  line.geometry.maxInstancedCount = value * (100 * coef);
};

const generateTranslations = (width, height) => ([
  `translate(-${width * 0.01}px, ${height * 0.03}px)`,
  `translate(${width * 0.02}px, -${height * 0.04}px)`,
  `translate(-${width * 0.01}px, ${height * 0.03}px)`,
  `translate(${width * 0.025}px, -${height * 0.04}px)`,
  `translate(-${width * 0.01}px, ${height * 0.03}px)`,
  `translate(${width * 0.03}px, -${height * 0.04}px)`
]);

const flatLine = (slide, index) => {
  let line = null;
  let start = [];
  let myar = [];
  let art = [];
  let min = [];
  let max = null;
  let len = 0;

  const o = { p: 0 };

  return gsap
    .to
      (
        o,
        1,
        {
          p:1,
          onStart: () => {
            line = slide.getLines()[index];
            start = line.geometry.attributes.instanceStart;
            myar = line.geometry.getPositions(start.data.array);

            art = [];
            /* eslint-disable */
            min = myar[0];
             /* eslint-enable */
            max = myar[myar.length - 3];
            len = myar.length;

            for (let i = 0; i <= len; i+=3) {
              art.push(lerp(min, max, i / len), 0, 0)
            }

            o.p = 0;
          },
          onUpdate: () => {
            const ar = [];
            for (let i = 0; i < len; i++) {
              ar.push(lerp(myar[i],art[i],o.p));
            }
            if (line) line.geometry.setPositions(ar);
          }
        }
      )
    ;
};

const timeline = ({ slide, lines, container, id, music }) => {
  const {
    innerHeight,
    innerWidth
  } = window;

  const isMod = container.querySelector(CLASSES.blockMod);

  const containers = document.querySelectorAll(CLASSES.block);
  const translates = generateTranslations(innerWidth, innerHeight);

  const animations = [...containers]
    .map((item, index, arr) => {
      const title = item.querySelector(CLASSES.headline);
      const text = item.querySelector(CLASSES.text);
      const lastTitle = item.querySelector(CLASSES.lastHeadline);

      const partials = [...title.querySelectorAll('image')];
      if (lastTitle) partials.push(lastTitle);

      const prevLine = lines[index - 1];

      const tl = gsap.timeline({ paused: true });

      let itter = 0;

      if (index) tl.to(arr[index - 1], 1, { autoAlpha: 0 });
      if (prevLine) tl.add(flatLine(slide, index - 1));

      if (index && prevLine) {
        tl
          .to(
            progress,
            1.5,
            {
              x: () => isMod ? 0 : START_AT,
              ease: 'power2.out',
              onUpdate: () => updateLineValue(slide.getLines()[index - 1], progress.x)
            }
          )
          .fromTo([item, title], 1, { autoAlpha: 0 }, { autoAlpha: 1 }, '-=1')
        ;
      } else {
        tl
          .fromTo(progress,
            1,
            {
              x: 0
            },
            {
              x: START_AT,
              ease: 'power2.out',
              onStart: () => {
                if (id === 'second') {
                  music.play('trying');
                  setTimeout(() => music.playTrack('trying-0'), 500);
                } else {
                  music.playTrack('trying-0');
                }
              },
              onUpdate: () => { if (!isMod) updateLineValue(slide.getLines()[index], progress.x)}
            },
            '+=1'
          )
          .fromTo([item, title], 1, { autoAlpha: 0 }, { autoAlpha: 1 }, '-=1')
        ;
      }

      tl
        .fromTo(
          progress,
          1.5,
          {
            x: 0
          },
          {
            x: 2,
            ease: 'power2.out',
            onStart: () => {
              if (!index) music.stopCurrentTrack();
              if (index) music.playTrack(`trying-${index}`);

              const line = slide.getLines()[index - 1];
              if (line) line.visible = false;

              window.analytics.trackPage('trying', `${tags.pages.story[id].subpage}${tags.pages.trying}${index + 1}`);
            },
            onUpdate: () => updateLineValue(slide.getLines()[index], progress.x)
          },
          '-=0.2'
        )
      ;

      if (!isMod) {
        tl
          .to(
            partials,
            1.5,
            {
              transform: () => {
                itter += 1;
                return lastTitle ? 'translate(0, 0)' : translates[itter - 1];
              }
            },
            '-=1.2'
          )
        ;
      };

      tl.fromTo(text, 1, { autoAlpha: 0 }, { autoAlpha: 1 }, '-=1.5')

      return tl;
    })
  ;

  return animations;
};

const reverse = ({ canvas }) => gsap
  .timeline()
  .fromTo(
    imageProgress,
    1,
    {
      value: 1
    },
    {
      value: 0,
      ease: 'power4.out',
      onUpdate: () => {
        canvas.setBrokenImageProgress(imageProgress.value);
      }
    },
    '-=1'
  )
;

const forwards = ({ id, music }) => ({
  play() {
    if (id === 'second') music.play('trying');
  }
});

export default {
  name: 'trying',
  reverse: {
    reverse,
    forwards
  },
  timeline
};

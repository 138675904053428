export default class Audio {
  constructor(config) {
    this.classes = {
      active: 'is-playing'
    };

    this.container = config.container;
    this.isPlaying = false;

    this.init();
  }

  getContainer() {
    return this.container;
  }

  pause() {
    this.isPlaying = false;

    this.audio.pause();
    this.container.classList.remove(this.classes.active);
  }

  play() {
    this.isPlaying = true;

    this.audio.play();
    this.container.classList.add(this.classes.active);
  }

  createAudio() {
    const src = this.container.dataset && this.container.dataset.src;
    if (!src) return;

    const audio = document.createElement('audio');

    audio.src = src;
    audio.preload = true;
    audio.style.display = 'none';

    this.container.append(audio);
    this.audio = audio;
  }

  init() {
    if (!this.container) return;

    this.createAudio();
  }
}

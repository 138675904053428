/* eslint class-methods-use-this: ["error", { "exceptMethods": ["startAnimation", "starAllAutoplayVideos"] }] */

import gsap from 'gsap';
import PerfectScrollbar from 'perfect-scrollbar';
import Scroll from '../lib/scroll';

import Slide from './Slide';
import Progress from './Progress';
import Audio from './Audio';
import Sounds from './Sounds';

import CONSTS from '../consts';

const { tags } = CONSTS;

const normalize = (value, min, max) => {
  const progress = Math.abs((min - value) / (max - min));

  if (progress > 1) return 1;
  return progress;
};

export default class Museum {
  constructor({
    canvas,
    music,
    states
  }) {
    this.classes = {
      container: '.js-museum',
      content: '.js-content',
      timeline: '.js-timeline',
      audio: '.js-audio',
      canvas: {
        image: '.js-canvas-img',
        parallax: '.js-canvas-parallax-img'
      },
      scrollbar: '.js-perfect-scrollbar',
      arrows: {
        both: '.js-timeline-arrows',
        left: '.js-timeline-arrow-left',
        right: '.js-timeline-arrow-right'
      },
      sections: {
        history: '.js-history',
        tabs: '.js-tabs'
      }
    };

    this.directions = {
      back: 'back',
      forward: 'forward'
    };

    this.data = {
      first: {
        brokenImage: './img/broke-blured-1.png'
      },
      second: {
        brokenImage: './img/broke-blured-2.png'
      }
    };

    this.blockedSections = ['video'];

    this.canvas = canvas;
    this.music = music;
    this.states = states;
    this.sounds = new Sounds();

    this.slides = [];

    this.current = 0;

    this.timelineProgress = {
      x: 0
    };

    this.isScrolling = false;
    this.movedByCustomScroll = false;

    this.container = document.querySelector(this.classes.container);
    this.content = document.querySelector(this.classes.content);

    this.raf = this.raf.bind(this);

    this.handleScroll = this.handleScroll.bind(this);
    this.handleScrollUpdate = this.handleScrollUpdate.bind(this);

    this.scrollToNext = this.scrollToNext.bind(this);
    this.scrollToPrev = this.scrollToPrev.bind(this);

    this.moveNext = this.moveNext.bind(this);
    this.movePrev = this.movePrev.bind(this);

    this.onStartAnimation = this.onStartAnimation.bind(this);

    this.hideArrows = this.hideArrows.bind(this);
    this.showArrows = this.showArrows.bind(this);

    this.trackMoveEvent = this.trackMoveEvent.bind(this);

    this.initSounds = this.initSounds.bind(this);
  }

  getPage() {
    return this.id;
  }

  getSlide(name) {
    return this.slides.find(slide => slide.name === name);
  }

  changeVideoVolume(volume) {
    const videoSlide = this.getSlide('video');
    if (!videoSlide) return;

    videoSlide.video.changeVolume(volume);
  }

  unmuteVideo() {
    const videoSlide = this.getSlide('video');
    if (!videoSlide) return;

    videoSlide.unmute();
  }

  muteVideo() {
    const videoSlide = this.getSlide('video');
    if (!videoSlide) return;

    videoSlide.mute();
  }

  raf() {
    if (this.scroll) this.scroll.calcScrollPos();
    if (this.canvas) this.canvas.render();

    window.requestAnimationFrame(this.raf);
  }

  setScrolledContainer() {
    this.timeline = this.container.querySelector(this.classes.timeline);
  }

  unmute() {
    this.unmuteVideo();
  }

  mute() {
    this.muteVideo();
  }

  starAllAutoplayVideos() {
    const videos = document.querySelectorAll('video[autoplay]');
    [...videos].forEach(item => item.play());
  }

  reinitStory() {
    document.body.click();

    this.initModules();
    this.initHandlers();

    this.sectionChanged(0);
    this.play(this.slides[this.current]);

    if (this.states.muted) {
      this.muteVideo();
    } else {
      this.unmuteVideo();
    }

    this.starAllAutoplayVideos();
  }

  reinit() {
    this.setValues();
    this.initMusic();

    if (this.id === 'intro') this.initSounds();
    if (this.timeline) this.reinitStory();
  }

  destroy() {
    this.destroyScrollbar();
    this.destroyHandlers();
    this.destroyModules();
  }

  resize() {
    window.clearTimeout(this.resizeTimeout);

    this.size = {
      x: window.innerWidth,
      y: window.innerHeight
    };

    this.resetStylesForSlidesInners();

    const gallery = this.slides.find(item => item.name === 'gallery');

    if (this.scroll && gallery) {
      this.scroll.smooth = false;
      this.scroll.setScrollPos(0);
    }

    this.resizeTimeout = window.setTimeout(() => {
      this.canvas.resize();

      this.slides.forEach((item, index) => {
        item.resize();

        const isHistory = item.name === 'history';

        const lines = item.getLines();
        lines.forEach(line => {
          if (index === this.current) return;

          const { position } = line;
          position.x = index < this.current ? -this.size.x : this.size.x;

          if (isHistory && (this.current > item.index)) position.x = -this.size.x * 2;
        });
      });

      this.setStylesForSlidesInners();

      if (this.scroll) this.scroll.smooth = true;
    }, 200);
  }

  setCurrentSlide() {
    this.currentSlide = this.slides[this.current];
    if (this.currentSlide && this.currentSlide.isScrolled) this.movedByCustomScroll = true;
  }

  scrollToPrev() {
    this.scrollTo(this.current - 1);
  }

  scrollToNext() {
    this.scrollTo(this.current + 1);
  }

  trackMoveEvent(action, direction) {
    const category = this.getPage() === 'first' ? 'story-1' : 'story-2';
    const isScroll = (action === tags.actions.scroll.action);

    const { next, prev } = isScroll ? tags.actions.scroll : tags.actions.arrow;

    window.trackAction(action, {
      event_category: category,
      event_label: (direction || this.direction) > 0 ? prev : next
    });
  }

  scrollTo(index, isCustom) {
    if (this.isChangingSectionInProgress) return;
    this.isChangingSectionInProgress = true;

    this.direction = index > this.current ? -1 : 1;

    const current = this.slidesContainers[this.current];
    const next = this.slidesContainers[index];

    if (!next) {
      this.isChangingSectionInProgress = false;
      this.resetScrolling();

      return;
    }

    this.pause();

    const currentSlide = this.slides[this.current];
    const nextSlide = this.slides[index];
    const currentLines = (this.current === this.total - 1) ? [] : currentSlide.getLines();
    const nextLines = (index === this.total - 1) ? [] : nextSlide.getLines();
    const currentSlideData = currentSlide.data;
    const nextSlideData = nextSlide.data;

    const finishScrollPosX = this.direction > 0 ? nextSlideData.end.x : nextSlideData.start.x;
    const startScrollPosX = this.direction > 0 ? currentSlideData.start.x : currentSlideData.end.x;

    const x = this.size.x * this.direction;

    const ease = (this.currentSlide.name === 'just-say') ? 'power2.out' : 'linear';
    const speed = 1.2;

    const tl = gsap.timeline({
      paused: true,
      onStart: () => {
        this.pauseAllAudios();

        this.scroll.smooth = false;
        this.scroll.setScrollPos(-startScrollPosX, 0);

        this.trackMoveEvent(isCustom ? tags.actions.arrow.action : tags.actions.scroll.action);
        this.hideArrows();
      },
      onComplete: () => {
        this.prevSlide = this.currentSlide;

        this.sectionChanged(index);
        this.scroll.setScrollPos(-finishScrollPosX, 0);

        setTimeout(() => {
          this.isChangingSectionInProgress = false;
          this.scroll.smooth = true;
        }, 200);
      }
    });

    const beforeEndAnimation = currentSlide.getBeforeEndTimeline();
    if (this.direction < 0 && beforeEndAnimation) { tl.add(beforeEndAnimation.play(0)); };

    tl
      .to(
        current.outer,
        speed,
        {
          x,
          ease,
          onStart: () => {
            this.play(nextSlide);

            this.progress.setCurrent(index + 1);
            this.canvas.smokeMove(this.direction * -1);
          }
        }
      )
      .to(next.outer, speed, { x: 0, ease }, `-=${speed}`)
      .to(this.scroll.current, speed, { x: -finishScrollPosX, ease }, `-=${speed}`)
      .to(this.timelineProgress, speed, { x: () => finishScrollPosX / this.maxLength }, `-=${speed}`)
    ;

    if (currentLines.length) currentLines.forEach(item => tl.add(
      gsap.fromTo(
        item.position,
        speed,
        {
          x: 0
        },
        {
          x,
          ease
        },
        `-=${speed}`
      ),
      `-=${speed}`)
    );

    if (nextLines.length) nextLines.forEach(item => tl.add(
      gsap.fromTo(
        item.position,
        speed,
        {
          x: -x
        },
        {
          x: 0,
          ease
        },
        `-=${speed}`
      ),
      `-=${speed}`)
    );

    tl.play();
  }

  updateAnimationByProgress(progress) {
    if (!this.currentSlide || !this.currentSlide.data) return;

    const currentSceneProgress = normalize(
      this.pos.x,
      this.currentSlide.data.start.x,
      this.currentSlide.data.end.x
    );

    if ((progress <= this.slidesProgress[0]) && this.currentSlide.name === 'gallery') {
      if ((currentSceneProgress > 0.8 && currentSceneProgress <= 1)) this.canvas.setBrokenImageOpacity(normalize(currentSceneProgress, 0.8, 1) * 0.3);
      if (currentSceneProgress < 0.8) this.canvas.setBrokenImageOpacity(0);
    }
  }

  setCanvasPos(x) {
    this.timelineProgress.x = this.pos.x / this.maxLength;
    this.updateAnimationByProgress(this.timelineProgress.x);
    this.canvas.setScrollPos(x, 0);
  }

  handleScrollUpdate({ x }) {
    if (!this.currentSlide) return;

    const {
      start,
      end
    } = this.currentSlide.data;

    const absX = Math.abs(x);

    const isExtremePoint = ((absX > end.x) || (absX < start.x));
    if (isExtremePoint && this.movedByCustomScroll) {
      this.movedByCustomScroll = false;
      // if (this.currentSlide.name === 'gallery') this.moveNext();
    }

    const { inner } = this.slidesContainers[this.current];
    if (inner) gsap.set(inner, { x: x - start.x });

    if (this.movedByCustomScroll || this.isChangingSectionInProgress) {
      if (!this.isGalleryScrolled && x < 0) {
        this.isGalleryScrolled = true;
        window.analytics.trackPage('photos', `${tags.pages.story[this.id].subpage}${tags.pages.photos}`);
      }

      this.setCanvasPos(x);
    }

    this.pos.x = x;
  }

  isExtremePoint(index) {
    const x = Math.abs(this.pos.x) + this.size.x;

    const isExtremeStartPoint = (index > this.current) && (this.currentSlide.data.start.x <= (x + this.size.x));
    const isExtremeEndPoint = (index < this.current) && (this.currentSlide.data.end.x >= (x - this.size.x));

    return isExtremeStartPoint && isExtremeEndPoint;
  }

  isScrollInsideSection() {
    return (Math.abs(this.pos.x) >= this.currentSlide.data.start.x) && (Math.abs(this.pos.x) < this.currentSlide.data.end.x);
  }

  isMoveInsideSection(index) {
    return this.isScrollInsideSection() && !this.isExtremePoint(index);
  }

  blockScrolling() {
    this.isScrolling = true;
    this.movedByCustomScroll = false;
  }

  resetScrolling(isArrowsShown) {
    this.isScrolling = false;
    this.movedByCustomScroll = true;

    this.canvas.setGalleryMovement();

    if (!isArrowsShown) this.showArrows();
  }

  pause() {
    this.currentSlide.pause();
  }

  getResetTimeout(duration = 1.2) {
    window.clearTimeout(this.timeout);

    this.isCustom = false;

    const delay = (duration > 1.2) ? 100 : Math.abs(duration * 1000 - 1200);
    return  window.setTimeout(() => {
      this.resetScrolling();
      this.showArrows();
    }, delay);
  }

  onGalleryEntered() {
    this.movedByCustomScroll = true;
    this.canvas.setGalleryMovement();

    if (this.direction > 0) {
      this.canvas.setBrokenImageProgress(1);
    } else {
      this.canvas.setBrokenImageProgress(0);
    }
  }

  onVideoEntered() {
    const lines = this.slides[this.current - 2].getLines();
    const currentLine = lines[0];

    if (this.direction > 0) {
      gsap.to(currentLine.position, 1.2, { x: -this.size.x });
    }
  }

  onFinalEntered() {
    const lines = this.slides[this.current - 1].getLines();
    const currentLine = lines[0];

    if (this.direction < 0) {
      gsap.to(currentLine.position, 1.2, { x: -this.size.x * 2 });
    }
  }

  onSonEntered() {
    const lines = this.slides[this.current + 1].getLines();
    lines[0].geometry.maxInstancedCount = 0;
  }

  onStartAnimation() {
    switch(this.currentSlide.name) {
      case 'gallery':
        this.onGalleryEntered();
        break;
      case 'final':
        this.onFinalEntered();
        break;
      case 'video':
        this.onVideoEntered();
        break;
      case 'son':
        this.onSonEntered();
        break;
      default:
    }
  }

  hideArrows() {
    gsap.to([this.classes.arrows.left, this.classes.arrows.right], 0.6, { autoAlpha: 0.3 });
  }

  showArrows() {
    gsap.to([this.classes.arrows.left, this.classes.arrows.right], 0.6, { autoAlpha: 1 });
  }

  hideLeftArrow() {
    gsap.to(this.classes.arrows.left, 1, { autoAlpha: 0, onComplete: () => gsap.set(this.classes.arrows.left, { display: 'none' }) });
  }

  showLeftArrow() {
    gsap.to(this.classes.arrows.left, 1, { autoAlpha: 0, onStart: () => gsap.set(this.classes.arrows.left, { display: 'block' }) }, { autoAlpha: 1 });
  }

  checkLeftArrowVisibility(tl) {
    if (!tl) return;

    if (tl.animations && tl.animations[tl.animations.length - 1].progress() !== 1) {
      this.hideLeftArrow();
    } else {
      this.showLeftArrow();
    }
  }

  play(slide) {
    this.blockScrolling();

    const direction = this.direction && this.direction < 0;

    const tl = slide.getTimeline(() => {
      slide.start();
      this.timeout = this.getResetTimeout(tl.duration ? tl.duration() : undefined);
    });

    this.checkLeftArrowVisibility(tl);

    const isFirstAnimationDone = tl && tl.progress() === 1;

    if (tl && !isFirstAnimationDone) {
      if (tl.eventCallback) tl.eventCallback('onStart', this.onStartAnimation);
      this.tl = tl;
      tl.play();

      return;
    }

    const secondTl = (isFirstAnimationDone && direction) ? slide.getForwardsTimeline() : slide.getReverseTimeline();

    this.checkLeftArrowVisibility(secondTl);

    if (secondTl && secondTl.eventCallback) {
      secondTl
        .eventCallback('onStart', this.onStartAnimation)
        .eventCallback('onComplete', () => {
          this.timeout = this.getResetTimeout(secondTl.duration ? secondTl.duration() : undefined);
        })
        .play(0)
      ;
    }

    if (secondTl) {
      secondTl.play();
      this.tl = secondTl;
    } else {
      this.tl = undefined;
    }

    this.timeout = this.getResetTimeout(1);
  }

  sectionChanged(index) {
    this.current = index;
    this.setCurrentSlide();
  }

  scrollInsideSection() {
    const { x: duration } = this.currentSlide.data.duration;

    const totalSteps = duration / (this.size.x * 0.5);
    const offset = duration / totalSteps - this.size.x;

    const x = this.scroll.target.x + offset * this.direction;
    const maxX = duration - this.size.x + 1;

    if (x > 0) {
      this.scroll.scrollTo(0, 0);
    } else if (maxX < Math.abs(x)) {
      this.scroll.scrollTo(-(maxX + 10) * this.direction, 0);
    } else {
      this.scroll.scrollTo(x, 0);
    }
  }

  movedInsideSection() {
    this.movedByCustomScroll = true;
    this.isScrolling = false;

    if (this.isCustom) this.scrollInsideSection();
  }

  handleScroll(e) {
    if (e.preventDefault) e.preventDefault();
    if (e.stopPropagation) e.stopPropagation();

    if (this.isChangingSectionInProgress) return;
    if (this.isScrolling) return;
    if (this.blockedSections.some(name => this.currentSlide.name === name) && !this.isCustom) return;

    this.blockScrolling();

    const delta = Math.abs(e.deltaX) > Math.abs(e.deltaY) ? e.deltaX : e.deltaY;
    this.direction = (delta > 0) ? 1 : -1;

    const current = this.current + this.direction;

    if (this.isMoveInsideSection(current)) {
      this.movedInsideSection();
      return;
    }

    this.movedByCustomScroll = false;

    this.hideArrows();

    if (current < 0 || current >= this.total) {
      this.resetScrolling();
      return;
    }

    const isForward = current > this.current;
    const currentSlide = this.slides[this.current];
    const slide = isForward ? this.slides[this.current + 1] : this.slides[this.current - 1];

    if (currentSlide.container.querySelector(this.classes.scrollbar)) {
      this.resetScrolling();
      return;
    }

    this.scroll.setScrollMax(slide.data.end.x, 0);
    this.scrollTo(current, e.isCustom);
  }

  checkScrollbar(isForward) {
    const scrollbar = this.currentSlide.container.querySelector(this.classes.scrollbar);
    if (!scrollbar) return false;

    const direction = isForward ? 1 : -1;
    const y = scrollbar.scrollTop + 150 * direction;

    scrollbar.scrollTo(0, y);

    return true;
  }

  enableBtnClick() {
    this.isCustom = true;
  }

  moveNext(e) {
    if (this.checkScrollbar(true)) return;
    if (this.isChangingSectionInProgress) return;

    this.trackMoveEvent(tags.actions.arrow.action, -1);

    this.enableBtnClick();
    this.handleScroll({ deltaY: 1, deltaX: 1, isCustom: Boolean(e) });
  }

  movePrev(e) {
    if (this.checkScrollbar()) return;
    if (this.isChangingSectionInProgress) return;

    this.trackMoveEvent(tags.actions.arrow.action, 1);

    this.enableBtnClick();
    this.handleScroll({ deltaY: -1, deltaX: -1, isCustom: Boolean(e) });
  }

  destroyBtnsHandlers() {
    this.btnLeft.removeEventListener('click', this.movePrev);
    this.btnRight.removeEventListener('click', this.moveNext);
  }

  initBtnsHandlers() {
    this.destroyBtnsHandlers();

    this.btnLeft.addEventListener('click', this.movePrev);
    this.btnRight.addEventListener('click', this.moveNext);
  }

  destroyHandlers() {
    this.content.removeEventListener(CONSTS.wheelEvent, this.handleScroll, { passive: false });
    if (this.btnLeft && this.btnRight) this.destroyBtnsHandlers();
  }

  initHandlers() {
    this.content.addEventListener(CONSTS.wheelEvent, this.handleScroll, { passive: false });
    if (this.btnLeft && this.btnRight) this.initBtnsHandlers();
  }

  resetStylesForSlidesInners() {
    this.slides.forEach((item) => {
      const { container } = item;
      container.setAttribute('style', `
        position: relative;
      `);
    });
  }

  setStylesForSlidesInners(isOnInit = false) {
    const { innerWidth } = window;

    let { x } = this.size;

    this.slides
      .forEach((item, index) => {
        const { container } = item;

        container.setAttribute('style', `
          left: 0;
          position: absolute;
          top: 0;
          max-width: ${innerWidth}px;
        `);

        if (isOnInit && index) {
          container.style.transform = `translate(${innerWidth}px, 0)`;
        } else if (!isOnInit) {
          if (this.currentSlide && (item.name === this.currentSlide.name)) {
            container.style.transform = `translate(0px, 0px)`;
            x *= -1;
          } else {
            container.style.transform = `translate(${-x}px, 0px)`;
          }

          if (item.name === 'gallery') container.style.opacity = 1;
        }
      }
    );
  }

  destroyScrollbar() {
    if (!this.scroll) return;
    this.scroll.off();
  }

  initScrollbar() {
    this.destroyScrollbar();

    const container = document.querySelector(this.classes.timeline);

    this.scroll = new Scroll({ container });
    this.scroll.on();

    this.scroll.onUpdate = this.handleScrollUpdate;

    this.setStylesForSlidesInners(true);
  }

  initProgress() {
    this.progress = new Progress();

    this.progress.init();
    this.progress.setValues(this.current, this.total);
  }

  pauseAllAudios() {
    this.audio.forEach(audio => audio.pause());
  }

  handleAudioClick({ item, index }) {
    this.audio.forEach((audio, id) => {
      if (index === id) return;
      if (audio.isPlaying) audio.pause();
    });

    if (item.isPlaying) {
      item.pause();
    } else {
      item.play();
    }
  }

  initAudio() {
    const audioNodes = [...this.container.querySelectorAll(this.classes.audio)];

    this.audio = audioNodes.map(item => new Audio({
      container: item
    }));

    this.audio.forEach((item, index) => {
      const container = item.getContainer();
      container.addEventListener('click', this.handleAudioClick.bind(this, { item, index }));
    });
  }

  initCustomScrollbar() {
    const containers = [...document.querySelectorAll(this.classes.scrollbar)];

    this.scrollbars = containers.filter((item) => {
      const ps = new PerfectScrollbar(item, {
        wheelPropagation: true,
        useBothWheelAxes: true
      });

      if (item.closest(this.classes.sections.history)) {
        item.addEventListener('ps-y-reach-start', () => this.scrollToPrev());
        item.addEventListener('ps-y-reach-end', () => this.scrollToNext());
      }

      return ps;
    });
  }

  destroySections() {
    this.slides.forEach(item => item.destroy());
  }

  initSections() {
    const slides = [...document.querySelectorAll('[data-slide]')];
    if (!slides.length) return;

    this.slides = slides.map((container, index) => new Slide({
      index,
      container,
      canvas: this.canvas,
      data: this.data[this.id],
      id: this.id,
      music: this.music,
      methods: {
        next: this.scrollToNext,
        hideArrows: this.hideArrows,
        showArrows: this.showArrows,
        trackMoveEvent: this.trackMoveEvent,
        initSounds: this.initSounds
      },
      classes: {
        arrows: this.classes.arrows.both
      },
      scroll: this.pos
    }));

    this.slides.forEach(item => item.init());

    this.slidesContainers = this.slides.map(item => item.data.container);
    this.total = this.slides.length;

    this.maxLength = this.slides.reduce((res, item) => res + item.data.duration.x, -this.size.x);
    this.slidesProgress = this.slides.map(({ data }) => data.end.x / this.maxLength);

    this.initProgress();
    this.initScrollbar();
  }

  destroySounds() {
    this.sounds.destroy();
  }

  initSounds() {
    this.sounds.init();
  }

  initMusic() {
    this.music.setStory(this.id);
    this.music.init();
  }

  destroyModules() {
    this.destroySections();
  }

  initModules() {
    this.initSections();
    this.initAudio();
    this.initCustomScrollbar();
  }

  resetValues() {
    this.slides = [];
    this.slidesData = [];
    this.slidesContainers = [];

    this.current = 0;
    this.currentSlide = null;

    this.pos = {
      x: 0,
      y: 0
    };

    this.size = {
      x: window.innerWidth,
      y: window.innerHeight
    };

    this.isGalleryScrolled = false;

    this.btnLeft = this.container.querySelector(this.classes.arrows.left);
    this.btnRight = this.container.querySelector(this.classes.arrows.right);

    this.id = this.timeline && this.timeline.dataset ? this.timeline.dataset.story : 'intro';
  }

  setValues() {
    this.setScrolledContainer();
    this.resetValues();
  }

  init() {
    if (!this.container) return;

    this.setValues();
    this.reinit();
    this.raf();
  }
}

/* eslint-disable */
import {vec2} from 'gl-matrix';


function setmag(t, e, n) {
  const i = e[0];
      const o = e[1];
      let r = i * i + o * o;
  return r > 0 && (r = 1 / Math.sqrt(r), t[0] = e[0] * r * n, t[1] = e[1] * r * n), t;
}
function limit(t, e, n) {
  const i = e[0];
      const o = e[1];
      let r = i * i + o * o;
  return r > n * n && (r = 1 / Math.sqrt(r), t[0] = e[0] * n, t[1] = e[1] * n), t;
}




export default class Point{
	constructor(x,y, isStatic){
		this.pos = vec2.fromValues(x,y)
		this.original = vec2.fromValues(x,y)

		this.static = isStatic || false;

		this.vel = vec2.fromValues(0,0);
		this.acc =vec2.fromValues(0,0);
		this.style = '#fff';
		this.maxForce = 0.05;
		this.maxSpeed = 5;
		this.radius = 100;
		this.defaultSpeedKoef = 4;

		this.maxForce = 0.05;
		this.maxSpeed = 5;
		this.radius = 80;
		this.defaultSpeedKoef = 4;

		this.n = [];
	}

	calculateForces(mouse) {
	  // calc runaway
	  const runawayForce = this.runaway(mouse);
	  const goinhomeForce = this.goinghomeForce();
	  const neighbourForce = this.neighbourForce();

	  // calc going home
	  this.applyForce(runawayForce);
	  this.applyForce(goinhomeForce);
	  // this.applyForce(neighbourForce);
	}

	applyForce(force) {
	  this.acc = vec2.add(this.acc,this.acc,force);
	}

	runaway(mouse) {
	  let dist = vec2.create();
	  const meow = vec2.fromValues(mouse.x,mouse.y);
	  vec2.subtract(dist,meow,this.pos);
	  if(vec2.length(dist)<this.radius) {
	  	let koef = 1. - vec2.length(dist)/this.radius;
	    setmag(dist,dist,this.maxSpeed*0.5);
	    dist = vec2.scale(dist,dist,-1);
	    const runawayforce = vec2.create();
	    vec2.subtract(runawayforce,dist,this.vel);
	    limit(runawayforce,runawayforce,this.maxForce);
	    return runawayforce;
	  }
	  return vec2.fromValues(0,0);
	}

	goinghomeForce() {
	  let dist = vec2.create();
	  dist = vec2.subtract(dist,this.original,this.pos);
	  const length = vec2.length(dist);
	  let speedkoeff = this.defaultSpeedKoef;
	  if(length<this.radius) {
	    speedkoeff = this.maxSpeed * length/this.radius;
	    setmag(dist,dist,speedkoeff);
	  }
	  const force = vec2.create();
	  vec2.subtract(force,dist,this.vel);
	  limit(force,force,this.maxForce);
	  return force;
	}

	neighbourForce(){
		const force = vec2.create();
		this.n.forEach(n=>{
			let d = vec2.create();
			d = vec2.subtract(d,n.pos,this.pos)
			const length = vec2.length(d);
			setmag(d,d,this.maxSpeed*0.05);
			vec2.add(force,force,d);
		})
		limit(force,force,this.maxForce);
		return force;
	}

	getNeighbours(ar){
		ar.forEach(pp=>{
			let d = vec2.create();
			d = vec2.subtract(d,this.pos,pp.pos)
			const length = vec2.length(d);
			if(length<50){
				this.n.push(pp);
			}
		})
	}

	update(mouse) {
		if(!this.static){
			this.calculateForces(mouse);
			this.vel[0] = 0
			vec2.add(this.pos,this.pos,this.vel);
			vec2.add(this.vel,this.vel,this.acc);
			vec2.scale(this.acc,this.acc,0);
		}
	}
}
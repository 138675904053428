/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getCookieMuteState", "setCookieMuteState"] }] */

import gsap from 'gsap';

export default class Waves {
  constructor(config) {
    this.container = document.querySelector(config.container);

    this.config = {
      lineWidth: 1,
      color: '#fff',
      step: 5,
    };

    this.tl = null;
    this.reverseTl = null;

    this.paused = true;
  }

  draw() {
    const { 
      lineWidth,
      color
    } = this.config;

    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.context.lineWidth = lineWidth;
    this.context.strokeStyle = color;

    this.context.beginPath();
    this.points.forEach(item => this.context.lineTo(item.x, item.y));
    this.context.strokeWidth = 2;
    this.context.stroke();
    this.context.closePath();
  }

  stopAnimating() {
    window.cancelAnimationFrame(this.raf);
  }

  animate() {
    if (this.paused) return;

    this.raf = window.requestAnimationFrame(this.animate.bind(this));

    if (!this.tl.isActive() && !this.reverseTl.isActive()) {
      this.points = this.generatePoints();
    }

    this.draw();
  }

  getWavesState() {
    return this.muted;
  }

  pause() {
    if (this.paused && this.tl) this.tl.progress(1);

    this.muted = true;

    this.reverseTl = this.getReverseTl();
    this.reverseTl.play();

    this.setCookieMuteState(true);
  }

  play() {
    if (!this.paused && this.reverseTl) this.reverseTl.progress(1);

    this.muted = false;
    this.paused = false;

    this.tl = this.getTl();
    this.tl.play();

    this.setCookieMuteState(false);

    this.animate();
  }

  generatePoints() {
    const points = [];
    
    const width = this.canvas.width / this.ratio;
    const height = this.canvas.height / this.ratio;

    const maxHeight = height * 0.5;
    const speed = Date.now() * 0.007;
    const coef = this.config.step / width * this.config.step * 0.61;

    for (let x = 0; x <= width; x += 0.1) {
      const waveHeight = (x >= height * 0.5 ? height % x : x % height) * 0.8;
      const sin = Math.sin(coef * x - speed);
      const y = waveHeight * sin + maxHeight;

      points.push({ x, y });
    }

    return points;
  }

  createCanvas() {
    this.size = {
      width: this.container.clientWidth,
      height: this.container.clientHeight,
    };

    const { width, height } = this.size;

    this.canvas = document.createElement('canvas');
    this.context = this.canvas.getContext('2d');

    this.ratio = window.devicePixelRatio;

    this.canvas.width = width * this.ratio;
    this.canvas.height = height * this.ratio;

    this.canvas.style.width = width;
    this.canvas.style.height = height;

    this.canvas.style.transform = `scale(${this.size.width / this.canvas.width})`;
    this.canvas.style.transformOrigin = 'top left';

    this.context.scale(this.ratio, this.ratio);

    this.container.appendChild(this.canvas);
  }

  onReverseComplete() {
    this.paused = true;
    this.stopAnimating();
  }

  onStart() {
    this.animate();
  }

  getReverseTl() {
    return gsap
      .timeline({
        paused: true,
        onComplete: this.onReverseComplete.bind(this)
      })
      .staggerTo(
        this.points,
        1,
        {
          y: this.middleY
        }
      )
    ;
  }

  getTl() {
    const points = this.generatePoints();

    return gsap
      .timeline({
        paused: true,
        onStart: this.onStart.bind(this),
        onComplete: () => { this.points = points; }
      })
      .add(this.points.map((item, index) => gsap.to(item, 1, { y: points[index].y })))
    ;
  }

  getCookieMuteState() {
    const { cookie } = document;
    const cookies = cookie && decodeURIComponent(cookie).split(';').map((item) => {
      const arr = item.split('=');
      return {
        name: arr[0].trim(),
        value: arr[1].trim()
      };
    });

    return cookie && cookies.find(({ name }) => name === 'is_muted');
  }

  setCookieMuteState(state) {
    if (document.cookie) document.cookie = `is_muted=${state}`;
  }

  initValues() {
    this.middleY = (this.canvas.height / this.ratio) * 0.5;
    const cookieState = this.getCookieMuteState();

    this.muted = cookieState ? !(cookieState.value === 'true') : true;

    this.points = this.generatePoints();

    this.tl = this.getTl();
    this.reverseTl = this.getReverseTl();
  }

  init() {
    if (!this.container) return;

    this.createCanvas();
    this.initValues();
    this.draw();
  }
};

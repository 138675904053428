import gsap from 'gsap';

export default class Progress {
  constructor() {
    this.classes = {
      container: '.js-progress',
      total: '.js-progress-total',
      current: '.js-progress-current',
    };

    this.container = document.querySelector(this.classes.container);

    this.current = 1;
    this.total = 1;
  }

  setCurrent(value) {
    this.current = value;

    const x = -this.$current.querySelector('span').clientWidth * (value - 1);
    gsap.set(this.$current, { x });
  }

  setTotal(value) {
    this.total = value;
    this.$total.innerHTML = this.total;
  }

  renderCurrentValues(value) {
    this.$current.innerHTML = '';

    const total = value || this.total;

    for (let i = 0; i < total; i++) {
      this.$current.innerHTML += `<span>${i + 1}</span>`;
    }
  }

  setValues(current, total) {
    this.renderCurrentValues(total);
    this.setCurrent(current || this.current);
    this.setTotal(total || this.total);
  }

  initValues() {
    this.$current = this.container.querySelector(this.classes.current);
    this.$total = this.container.querySelector(this.classes.total);
  }

  init() {
    if (!this.container) return;

    this.initValues();
    this.setValues();
  }
};

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["stopPreviousAnimation", "isAudioUnlocked", "getHowlerMuteTl", "onUpdate"] }] */

import { Howl, Howler } from 'howler';

import gsap from 'gsap';

export default class Music {
  constructor(config) {
    this.audio = config.audio;
    this.options = {
      src: config.audio,
      autoplay: true,
      loop: true
    };

    this.states = {
      loaded: 'loaded'
    };

    this.speed = 5000;
    this.volume = 1;

    this.current = null;
    this.tl = null;

    this.tracks = [];
    this.sounds = [];

    this.howlerTl = this.getHowlerMuteTl();
  }

  setState(state) {
    this.isPaused = state;
  }

  resetCurrent() {
    if (!this.current) return;

    this.current.audio.volume(0);
    this.current = null;
  }

  playAll() {
    if (this.isPaused) return;
    if (this.sounds && this.sounds.length) this.sounds.forEach(item => item.audio.play());
  }

  pauseAll() {
    if (!this.isPaused) return;

    this.resetCurrent();
    if (this.sounds && this.sounds.length) this.sounds.forEach((item) => {
      item.audio.pause();
      item.audio.volume(0);
    });
  }

  stopPreviousAnimation(tl = this.tl) {
    if (tl) tl.progress(1).pause();
  }

  setVolume(volume) {
    this.stopPreviousAnimation();
    if (!this.current) return;

    this.volume = volume;

    this.current.audio.volume(volume);
  }

  changeVolume(value, callback = () => {}) {
    this.stopPreviousAnimation();
    if (!this.current) return;

    const obj = {
      volume: this.current.audio.volume()
    };

    this.tl = gsap.to(
      obj,
      5,
      {
        volume: value,
        onUpdate: () => {
          if (this.current) this.current.audio.volume(obj.volume);
        },
        onComplete: callback
      }
    )
  }

  crossfade(name = null) {
    this.stopPreviousAnimation(this.tl);

    if (this.isDestroyingInProgress) {
      this.currentName = name;
      return;
    }

    if (!this.sounds || !this.sounds.length) return;

    const nextSound = this.sounds.find(item => item.name === name);
    if (!nextSound) return;

    if (nextSound && nextSound.audio && nextSound.audio.state() !== this.states.loaded) nextSound.audio.load();

    const obj = {
      volume: 0
    };

    this.tl = gsap.to(
      obj,
      5,
      {
        volume: 1,
        onStart: () => {
          nextSound.audio.volume(0);
          if (!nextSound.audio.playing()) nextSound.audio.play();
        },
        onUpdate: () => {
          nextSound.audio.volume(obj.volume);
          if (this.current) this.current.audio.volume(1 - obj.volume);
        },
        onComplete: () => {
          this.current = nextSound;
        }
      }
    );
  }

  stopCurrentTrack() {
    const tracks = this.tracks.filter(({ audio }) => audio.playing());
    if (!tracks.length) return;

    const obj = tracks.map(item => ({
      volume: item.audio.volume()
    }));

    this.trackTl = gsap.to(
      obj,
      5,
      {
        volume: 0,
        onUpdate: () => {
          tracks.forEach((item, index) => item.audio.volume(obj[index].volume));
        },
        onComplete: () => {
          tracks.forEach(item => item.audio.pause());
        }
      }
    );
  }

  playTrack(name) {
    if(!this.isAudioUnlocked()) return;

    const nextTrack = this.tracks.find(item => item.name === name);
    if (!nextTrack) return;

    if (nextTrack && nextTrack.audio.state() !== this.states.loaded) nextTrack.audio.load();

    nextTrack.audio.volume(1);
    nextTrack.audio.play();
  }

  play(name) {
    this.name = name;
    this.crossfade(name);
  }


  onUpdate(callback) {
    Howler.volume(this.obj.volume);
    callback(this.obj.volume);
  }

  unmute(callback = () => {}) {
    const progress = this.howlerTl.progress();
    this.howlerTl
      .eventCallback('onUpdate', () => this.onUpdate(callback))
      .reverse(progress, false)
    ;
  }

  mute(callback = () => {}) {
    this.howlerTl
      .eventCallback('onUpdate', () => this.onUpdate(callback))
      .play()
    ;
  }

  getSectionsTracks() {
    const tracks = this.audio.sections[this.story];
    if (!tracks) return [];

    return this.audio.sections[this.story].map(({ name, src }) => ({
      name,
      audio: new Howl(
        {
          src,
          loop: false,
          autoplay: false,
          volume: 1,
          preload: true
        }
      )
    }));
  }

  getBackgroundTracks() {
    const tracks = this.audio.background[this.story];
    if (!tracks) return [];

    return this.audio.background[this.story].map(({ name, src }) => ({
      name,
      audio: new Howl(
        {
          src,
          loop: true,
          autoplay: false,
          volume: 0,
          preload: true
        }
      )
    }));
  }

  setStory(story) {
    this.story = story || 'intro';
  }

  initBackgroundTracks() {
    this.sounds = this.getBackgroundTracks();
  }

  initSectionTracks() {
    this.tracks = this.getSectionsTracks();
  }

  reinitSounds() {
    this.initBackgroundTracks();
    this.initSectionTracks();
  }

  isAudioUnlocked() {
    const { _audioUnlocked } = Howler;
    return _audioUnlocked;
  }

  getHowlerMuteTl() {
    this.obj = { volume: Howler.volume() };

    return gsap.fromTo(
      this.obj,
      2,
      {
        volume: 1
      },
      {
        volume: 0
      }
    );
  }

  playMutedTrack() {
    if (this.currentName) this.crossfade(this.currentName);
    this.currentName = null;
  }

  setVolumeAnimWhenUnlocked() {
    this.howler.volume(0);

    document.body.addEventListener(
      'click',
      () => {
        const obj = {
          value: 0
        };

        gsap.to(
          obj,
          5,
          {
            value: 1,
            onStart: () => {
              this.howler.volume(0);
              if (this.current) this.current.audio.volume(0);
            },
            onUpdate: () => {
              this.howler.volume(obj.value);
              if (this.current) this.current.audio.volume(obj.value);
            },
            onComplete: () => this.playMutedTrack()
          }
        );
      },
      { once: true }
    );
  }

  destroy(callback = () => {}) {
    if (this.current === null) {
      callback();

      return;
    }

    const obj = { value: this.current.audio.volume() };

    gsap
      .to(
        obj,
        1,
        {
          value: 0,
          onUpdate: () => this.current.audio.volume(obj.value),
          onComplete: () => {
            this.tracks.forEach(item => item.audio.unload());
            this.sounds.forEach(item => item.audio.unload());

            this.sounds = [];
            this.tracks = [];

            callback();
          }
        }
      )
    ;
  }

  init() {
    this.howler = Howler;

    this.isDestroyingInProgress = true;

    this.destroy(() => {
      this.isDestroyingInProgress = false;

      this.reinitSounds();

      if (!this.isAudioUnlocked()) {
        this.setVolumeAnimWhenUnlocked();
      } else {
        this.playMutedTrack();
      }
    });
  }
};
